import { Toast } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import Qrcode from 'qrcode.react'
import { getUserAssets } from '../../../api/lease'
import { ToastFail } from '../../../utils/http/err_handle'
import styles from './index.module.scss'
import Clipboard from 'clipboard'
import { getLanguage } from '../../../locate'

function Recharge() {
  const l = getLanguage().All
  const [address, setAddress] = useState('');
  useEffect(() => {
    document.title = l.recharge1
    const clipboard = new Clipboard("#copy-btn");
    clipboard.on('success', () => {
      Toast.success(l.recharge2)
    })
    getUserAssets()
      .then(({data}) => {
        setAddress(data.rechargeAddress)
    })
      .catch(ToastFail)
    return () => {
      clipboard.destroy();
    }
  }, [])

  return (
    <div className={styles.main}>
      <div className={styles.recharge}>
        <div className={styles.currency}>FIL-Filecoin</div>
        <div className={styles.qrcodeContainer}>
          <div className={[styles.parcel, styles.tl].join(' ')} />
          <div className={[styles.parcel, styles.tr].join(' ')} />
          <div className={[styles.parcel, styles.br].join(' ')} />
          <div className={[styles.parcel, styles.bl].join(' ')} />
          <div className={styles.qrcode}>
            <Qrcode value={address} />
          </div>
        </div>
        <div className={styles.rechargeTitle}>
          {l.recharge3}
        </div>
        <div className={styles.address}>{address}</div>
        <div className={styles.copy}>
          <button id="copy-btn" data-clipboard-text={address}>{l.recharge4}</button>
        </div>
      </div>
      <div className={styles.tips}>
        <div className={styles.title}>{l.recharge5}</div>
        <p style={{color: '#FF8400'}}>1、{l.recharge6}</p>
        <p>2、{l.recharge7}</p>
      </div>
    </div>
  )
}

export default Recharge
