import { Toast, WhiteSpace } from 'antd-mobile'
import { Observer, useLocalObservable } from 'mobx-react-lite'
import React, { FormEvent, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { updateStrategy } from '../../api/strategy'
import { LOCAL_STRATEGY_ID } from '../../lib/global-key'
import { ToastFail } from '../../utils/http/err_handle'
import { ObjectToString } from '../../utils/tools'
import { AmountVerification } from '../../utils/verification/form-verification'
import del from '../setdeal/imgs/delete.png'
import styles from './add-lot.module.css'
import NavBar from '../../components/nav-bar/NavBar'
import { getLanguage } from '../../locate'


const InitialParam = {
  bt_pi: '',
  down_pi: '',
  down_return_pi: '',
}
const InitialState = {
  name: '',
  type: 1, // 0 日间, 1 夜间
  symbol: '',
  first_amount: '',
  stop_pi: '',
  stop_return_pi: '',
  params: [
    InitialParam,
  ],
}

function AddLot() {
  const l = getLanguage().All
  const location = useLocation()
  const history = useHistory()
  const estate = location.state as typeof InitialState || InitialState
  const id = localStorage.getItem(LOCAL_STRATEGY_ID)
  const data = useLocalObservable(() => ({
    ...estate,
    remove_param(index: number) {
      this.params.splice(index, 1)
    }
  }))
  const [len] = useState(estate.params.length - 1)
  // const [data, setData] = useState()
  const handleChange = (key: keyof typeof data) => (event: FormEvent<HTMLInputElement>) => {
    (data[key] as unknown) = event.currentTarget.value === '' ? ''
      : (AmountVerification(event.currentTarget.value) || data[key]) as any;
  }
  const handleChangeIndex = (key: keyof typeof data.params[0], index: number) => (event: FormEvent<HTMLInputElement>) => {
    data.params[index][key] = event.currentTarget.value === '' ? ''
      : AmountVerification(event.currentTarget.value) || data.params[index][key];
  }
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const values = { ...ObjectToString(data), StrategyId: Number(id) };
    updateStrategy(values)
      .then(() => {
        Toast.info(l.update_success)
        history.goBack()
      })
      .catch(ToastFail)
  }
  return (
    <div className={styles.container}>
      <NavBar title={l.add_Hand_Number} theme="light"/>
      <Observer>
        {() => {
          let amount = Number(data.first_amount)
          return (
            <div className={styles.tabContainer}>
              <form onSubmit={handleSubmit}>
                <div className={styles.box}>
                  <div className={styles.boxhead}>
                    <div className={styles.boxhead_name}>{l.first_One}</div>
                    {/*<img srcSet={edit} className={styles.edit} alt=""/>*/}
                    {/*<img srcSet={del} alt=""/>*/}
                  </div>
                  <div style={{border:'1px solid #2C355C',width:'15px',marginTop:'7px'}} />
                  <div className={styles.flexblock}>
                    <div className={styles.col}>
                      <div className={styles.block}>
                        <label>{l.first_limit}</label>
                        <span className={styles.disable_input}>{data.first_amount}</span>
                        <span className={styles.unit}>
                          USDT
                        </span>
                      </div>
                    </div>
                    <div className={styles.col} />
                  </div>
                  <div className={styles.flexblock}>
                    <div className={styles.col}>
                      <div className={styles.block}>
                        <label>{l.check_surplus_proportion}</label>
                        <span className={styles.disable_input}>{data.stop_pi}</span>
                        <span className={styles.unit}>%</span>
                      </div>
                    </div>
                    <div className={styles.col}>
                      <div className={styles.block}>
                        <label>{l.add_lot1}</label>
                        <span className={styles.disable_input}>{data.stop_return_pi}</span>
                        <span className={styles.unit}>%</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.flexblock}>
                    <div className={styles.col}>
                      <div className={styles.block}>
                        <label>{l.add_lot2}</label>
                        <span className={styles.disable_input}>{data.params[0].down_pi}</span>
                        <span className={styles.unit}>%</span>
                      </div>
                    </div>
                    <div className={styles.col}>
                      <div className={styles.block}>
                        <label>{l.add_lot3}</label>
                        <span className={styles.disable_input}>{data.params[0].down_return_pi}</span>
                        <span className={styles.unit}>%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {data.params.slice(1).map((v: any, index: number) => (
                    <div className={styles.box} key={index}>
                      <div className={styles.boxhead}>
                        <div className={styles.boxhead_name}>{l.setDeal28_1}{index + 2}{l.setDeal28_2}</div>
                        {/*<img srcSet={edit} className={styles.edit} alt=""/>*/}
                        {index > len - 1 && (
                          <img onClick={() => {
                            data.remove_param(index + 1)
                          }} src={del} alt=""/>
                        )}
                      </div>
                      <div style={{border:'1px solid #2C355C',width:'15px',marginTop:'7px'}} />
                      <div className={styles.col}>
                        <div className={styles.block}>
                          <label>{l.add_lot4}</label>
                          {index > len - 1 ? (
                            <div className={styles.inp}>
                              <input required type="text" value={v.bt_pi} onInput={handleChangeIndex('bt_pi', index + 1)} />
                            </div>
                          ) : (
                            <span className={styles.disable_input}>{v.bt_pi}</span>
                          )}
                          <span className={styles.unit}>{l.setDeal25} ≈ {amount *= Number(v.bt_pi)} USDT</span>
                        </div>
                      </div>
                      <div className={styles.flexblock}>
                        <div className={styles.col}>
                          <div className={styles.block}>
                            <label>{l.add_lot5}</label>
                            {index > len - 1 ? (
                              <div className={styles.inp}>
                                <input required type="text" value={v.down_pi} onInput={handleChangeIndex('down_pi', index + 1)} />
                              </div>
                            ) : (
                              <span className={styles.disable_input}>{v.bt_pi}</span>
                            )}
                            <span className={styles.unit}>%</span>
                          </div>
                        </div>
                        <div className={styles.col}>
                          <div className={styles.block}>
                            <label>{l.add_lot3}</label>
                            {index > len - 1 ? (
                              <div className={styles.inp}>
                                <input required type="text" value={v.down_return_pi} onInput={handleChangeIndex('down_return_pi', index + 1)} />
                              </div>
                            ) : (
                              <span className={styles.disable_input}>{v.bt_pi}</span>
                            )}
                            <span className={styles.unit}>%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={styles.btns}>
                  <button className={styles.left_btn}>
                    {l.add_lot6}
                  </button>
                  <button
                    className={styles.right_btn}
                    type="button"
                    onClick={() => data.params.push(InitialParam)}
                  >
                    {l.add_lot7}
                  </button>
                </div>
              </form>
              <WhiteSpace size='md' />
            </div>
          )
        }}
      </Observer>
    </div>
  )
}

export default AddLot
