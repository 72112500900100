import React, { useEffect, useState } from 'react'
import { queryStrategyLibrary } from '../../api/strategy'
import Null from '../../components/null/Null'
import PullLoadList from '../../components/pull-load-list/PullLoadList'
import { ToastFail } from '../../utils/http/err_handle'
import sun from './imgs/sun.png'
import moon from './imgs/moon.png'
import styles from './tab3-deal.module.css'
import { getLanguage } from '../../locate'

interface Tab3Props {
  onSelected: (data: any) => void
}

function Tab3(props: Tab3Props) {
  const l = getLanguage().All

  const {onSelected} = props
  const [list, setList] = useState<any[]>([])
  useEffect(() => {

  }, [])
  return (
    <PullLoadList
      onReq={(pageNum =>
        queryStrategyLibrary(pageNum)
          .then(res => {
            const lists = res.data.lists
            setList(pageNum.pageNo === 1 ? lists : list.concat(lists))
            return lists
          })
          .catch(ToastFail)
      )}
      empty={!list.length && (
        <Null />
      )}
    >
      {list.map((v, i) => (
        <div
          className={styles.box}
          key={i}
          onClick={() => {
            onSelected(v)
          }}
        >
          <div className={styles.boxhead}>
            <div className={styles.boxhead_name}>{v.name}{/* <span>(已选)</span>*/}</div>
            <img srcSet={v.type === 0 ? sun : moon}  alt=""/>
          </div>
          <div style={{border:'1px solid #2C355C',width:'15px',marginTop:'7px'}} />
          <div className={styles.flexblock}>
            <div className={styles.block}>
              <span>{l.setDeal11}</span>
              <span>{v.firstAmount}USDT</span>
            </div>
            <div className={styles.block}>
              <span>{l.setDeal28}</span>
              <span>{v.btUp}{l.setDeal28_3}</span>
            </div>
          </div>
          <div className={styles.flexblock}>
            <div className={styles.block}>
              <span>{l.setDeal14}</span>
              <span>{v.stopPi}%</span>
            </div>
            <div className={styles.block}>
              <span>{l.setDeal23}</span>
              <span>{v.profitPi}%</span>
            </div>
          </div>
          <div className={styles.flexblock}>
            <div className={styles.block}>
              <span>{l.setDeal16}</span>
              <span>{v.replenishmentDownPi}%</span>
            </div>
            <div className={styles.block}>
              <span>{l.setDeal17}</span>
              <span>{v.replenishmentReturnPi}%</span>
            </div>
          </div>
        </div>
      ))}
      {/*<div className={styles.box}>
        <div className={styles.boxhead}>
          <div className={styles.boxhead_name}>夜间策略</div>
          <img srcSet={moon}  alt=""/>
        </div>
        <div style={{border:'1px solid #2C355C',width:'15px',marginTop:'7px'}} />
        <div className={styles.flexblock}>
          <div className={styles.block}>
            <span>首单额度</span>
            <span>20USDT</span>
          </div>
          <div className={styles.block}>
            <span>倍投上限</span>
            <span>6单</span>
          </div>
        </div>
        <div className={styles.flexblock}>
          <div className={styles.block}>
            <span>止盈比例</span>
            <span>1.3%</span>
          </div>
          <div className={styles.block}>
            <span>盈利回调</span>
            <span>0.3%</span>
          </div>
        </div>
        <div className={styles.flexblock}>
          <div className={styles.block}>
            <span>补仓跌幅</span>
            <span>10%</span>
          </div>
          <div className={styles.block}>
            <span>补仓回调</span>
            <span>0.5%</span>
          </div>
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.boxhead}>
          <div className={styles.boxhead_name}>小频策略</div>
          <img srcSet={sun}  alt=""/>
        </div>
        <div style={{border:'1px solid #2C355C',width:'15px',marginTop:'7px'}} />
        <div className={styles.flexblock}>
          <div className={styles.block}>
            <span>首单额度</span>
            <span>20USDT</span>
          </div>
          <div className={styles.block}>
            <span>倍投上限</span>
            <span>6单</span>
          </div>
        </div>
        <div className={styles.flexblock}>
          <div className={styles.block}>
            <span>止盈比例</span>
            <span>1.3%</span>
          </div>
          <div className={styles.block}>
            <span>盈利回调</span>
            <span>0.3%</span>
          </div>
        </div>
        <div className={styles.flexblock}>
          <div className={styles.block}>
            <span>补仓跌幅</span>
            <span>10%</span>
          </div>
          <div className={styles.block}>
            <span>补仓回调</span>
            <span>0.5%</span>
          </div>
        </div>
      </div>*/}
    </PullLoadList>
  )
}

export default Tab3
