import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import cookie from 'cookie'
import { hideAllLoading, showLoading } from '../../components/loading/showLoading'
import native_sdk from '../native_sdk'
import { urlQuery } from '../tools'
// const HOST_URL = {
//   development: 'http://192.168.5.16:8081/', // 'http://192.168.5.199:8056/','161.117.234.84:18080/' 192.168.5.111:8080
//   test: 'http://192.168.5.16:8081/',//http://161.117.234.84:18080/   https://Gluttonba.ejar.cn/
//   production: process.env.REACT_APP_HOST_URL || 'http://192.168.5.16:8081/',
// }

export function runLoading(config: AxiosRequestConfig = {}) {
  showLoading()
  return {
    ...config,
    params: {
      __loading: true,
      ...config.params,
    },
  };
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  timeout: 10000,
});

class ServerError extends Error {
  not_toast = false;

  constructor(data?: any) {
    super((data && (data.msg || data.msgContent)) || '');
    if (!data)
      this.not_toast = true;
  }
}


instance.interceptors.response.use(
  (value: { data?: any; config?: any }) => {
    const { config } = value;
    if (config.params && config.params.__loading) {
      hideAllLoading()
    }
    if (value.data.code === '311' || value.data.code === '401') {
      native_sdk.goLogin()
    }
    if (value.data.code !== '200') {
      return Promise.reject(
        new ServerError({
          ...value.data,
          url: config.url,
        })
      );
    }
    return { ...value.data, __old: value };
  },
  (err: any) => {
    // IAlert.loading.hide()
    hideAllLoading();
    return Promise.reject(err);
  }
);

instance.interceptors.request.use((value) => {
  console.log('process.env',process.env)
  // if (value.headers['Content-Type'].includes('application/x-www-form-urlencoded')) {
  //   value.data = JsonToUrlParams(value.data)
  // }
  return value;
});

export function setBaseURL(baseURL: string) {
  instance.defaults.baseURL = baseURL;
}

const cookies = cookie.parse(document.cookie)
// 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0TG9naW5UaW1lIjoiMTYxMjQzMjc5OTg1NSIsImxldmVsIjoiNSIsInJlZ2lzdGVyVGltZSI6IkZyaSBKYW4gMjkgMTU6MTM6MTUgQ1NUIDIwMjEiLCJuaWNrTmFtZSI6Iumaj-mjjiIsIm9ubHlMb2dpbiI6IjE2MTI0MzI3OTk4NjAiLCJoZWFkVXJsIjoiIiwidXNlcklkIjoiNSJ9.9uOliIXbkmxom64bYkquA_fjT-kLCvKqlFN88PK31Eo'
// instance.defaults.headers.token = process.env.NODE_ENV === 'development' ? localStorage.getItem('token') : cookies.token
instance.defaults.headers.token = cookies.token || localStorage.getItem('token')
instance.defaults.headers.langue = urlQuery().language || 'zh-CN'
// export function setToken(token: string) {
//   instance.defaults.headers.token = token
// }

const reqwest = {
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: { [ key: string ]: any },
    config?: AxiosRequestConfig
  ) {
    return instance.post<T, R>(url, data, config);
  },
  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) {
    return instance.get<T, R>(url, config);
  },
};

export default reqwest;
