import { PageNum } from '../components/pull_load_list/PullLoadList'
import { LoopType } from '../lib/enums'
import reqwest, { runLoading } from '../utils/http/reqwest'
import { ObjectToString } from '../utils/tools'

export function getUserAssets(coinType: string = '3') {
  return reqwest.post('/assetsCenter/getUserAssets', { coinType })
}

export function getProductPage(pageNum: PageNum) {
  return reqwest.post('/mining/getProductPage', ObjectToString(pageNum))
}

export function getOrderPage(pageNum: PageNum) {
  return reqwest.post('/mining/order/page', ObjectToString(pageNum))
}

type FilWithdrawParams = {
  amount: number,
  payPwd: string,
  extractAddress: string,
}

export function setFilWithdraw(params: FilWithdrawParams) {
  return reqwest.post('assetsCenter/setFilWithdraw', {
    coinType: 3,
    ...params,
  }, runLoading())
}

export function assetsDetails(type: number, pageNum: PageNum) {
  return reqwest.post('mining/wallet/page', { ...pageNum, walletType: type })
}

export function miningBuy(data: { buyAmount: number, productId: number,safePwd:string }) {
  return reqwest.post('/mining/buy', data)
}

export function getOrderInfo( orderId: string ) {
  return reqwest.post('/mining/order/info', { orderId })
}

export function getFilAddressByUid() {
  return reqwest.post('/assetsCenter/getFilAddressByUid',{})
}
export function deleteFilAddressById(id:number) {
  return reqwest.post('/assetsCenter/deleteFilAddressById',{id})
}
export function addNewFilAddress(form:{address:string,remark:string}) {
  return reqwest.post('/assetsCenter/addNewFilAddress',form, runLoading())
}
export function updateFilAddressById(form:{id: string,address:string,remark:string}) {
  return reqwest.post('/assetsCenter/updateFilAddressById',form, runLoading())
}
