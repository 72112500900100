import { Toast } from 'antd-mobile'
import React, { CSSProperties, useEffect, useRef } from 'react'
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from 'react-router-dom';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import cookie from 'cookie'
import AddLot from './pages/addLot/add-lot'
import Edit from './pages/leasingTreasure/address/edit'
import Insert from './pages/leasingTreasure/address/insert'
import LeaseAgreement from './pages/leasingTreasure/agreement'
import UserAgreement from './pages/leasingTreasure/userAgreement'
import AssetsDetail from './pages/leasingTreasure/assets-detail'
import FilTb from './pages/leasingTreasure/fil-tb'
import Recharge from './pages/leasingTreasure/recharge'
import TransactionDetail from './pages/transaction/transaction-detail/TransactionDetail'
import TransactionList from './pages/transaction/transaction-list/TransactionList'
import SetDeal from './pages/setdeal/SetDeal'
import LeasingTreasure from './pages/leasingTreasure/home'
import MyProduct from './pages/leasingTreasure/myProduct/myProduct'
import OrderInfo from './pages/leasingTreasure/orderInfo/orderInfo'
import ProductInfo from './pages/leasingTreasure/productInfo/productInfo'
import CommonProblem from './pages/commonProblem/problem'
import ProblemInfo from './pages/commonProblem/problemInfo'
import AddressList from './pages/leasingTreasure/address'
Toast.config({ mask: false })

function App() {
  useEffect(() => {
    let cookies = cookie.parse(document.cookie)
    if (cookies.token)
      localStorage.setItem('token', cookies.token)
  }, [])
  return (
    <Router>
      <AnimationRoute />
    </Router>
  );
}

function AnimationRoute() {
  let location = useLocation();
  // const nodeRef = createRef<HTMLDivElement>()
  const nodeRef = useRef(null)
  return (
    <div style={styles.fill} className="animation-container">
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={300}
          nodeRef={nodeRef}
        >
          <div ref={nodeRef} style={{ height: '100%' }}>
            <Switch location={location}>
              <Route path="/" exact>
                <TransactionList />
              </Route>
              <Route path="/transaction/detail">
                <TransactionDetail />
              </Route>
              <Route path="/transaction/strategy">
                <SetDeal />
              </Route>
              <Route path="/transaction/add-lot">
                <AddLot />
              </Route>
              <Route path="/leasingTreasure">
                <LeasingTreasure />
              </Route>
              <Route path="/lease/myProduct">
                <MyProduct />
              </Route>
              <Route path="/lease/orderInfo/:id">
                <OrderInfo />
              </Route>
              <Route path="/lease/productInfo">
                <ProductInfo />
              </Route>
              <Route path="/lease/recharge">
                <Recharge />
              </Route>
              <Route path="/lease/fil">
                <FilTb />
              </Route>
              <Route path="/lease/assets-detail">
                <AssetsDetail />
              </Route>
              <Route path="/lease/agreement">
                <LeaseAgreement />
              </Route>
              <Route path="/lease/userAgreement">
                <UserAgreement />
              </Route>
              <Route path="/commonProblem">
                <CommonProblem />
              </Route>
              <Route path="/problem/info/:id">
                <ProblemInfo />
              </Route>
              <Route path="/lease/fil_addressList">
                <AddressList/>
              </Route>
              <Route path="/lease/insertAddressList">
                <Insert/>
              </Route>
              <Route path="/lease/editAddressList">
                <Edit/>
              </Route>
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

const styles: {[key: string]: CSSProperties} = {};

styles.fill = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0
};

export default App;
