import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ToastFail } from '../../../utils/http/err_handle'
import styles from './index.module.scss'
import {getCommonProblem} from '../../../api/commonProblem'
import { getLanguage } from '../../../locate'

interface Problem{
  title:string,
  [key: string]: any
}
interface ProblemModule{
  title:string,
  icon:string,
  problems:Problem[],
  [key: string]: any
}
function Problem() {
  const l = getLanguage().All
  const [data,setData]=useState<ProblemModule[]>([])
  const history=useHistory()
  useEffect(() => {
    document.title = l.problem1
    getCommonProblem().then(res=>{
      setData(res.data)
    }).catch(ToastFail)
  }, [])
  return (
    <div className={styles.Problem}>
      {
        data.map((e:ProblemModule, i:number) => (
          <div className={styles.problemModule} key={i}>
            <div className={styles.moduleLeft}>
              <img src={e.icon} alt="" className={styles.moduleIcon}/>
              <div className={styles.moduleTitle}>
                {e.title}
              </div>
            </div>
            <div className={styles.moduleRight}>
              {
                e.problems.map((v:Problem, k:number) => (
                  <div className={styles.problemItem} key={k} onClick={()=>{
                    history.push(`/problem/info/${v.id}`)
                  }}>
                    {v.title}
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }

    </div>
  )
}

export default Problem
