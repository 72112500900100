import React from 'react'
import style from './ProgressBar.module.scss'
import { getLanguage } from '../../../locate'

interface Props {
  amount: number,//总额度
  spareAmount: number//剩余额度
}

function ProgressBar(props: Props) {
  const l = getLanguage().All
  const { amount, spareAmount } = props
  const getProgress = (): string => {
    if (!amount) {
      return '0.00'
    } else {
      return ((amount - spareAmount) / amount * 100).toFixed(2)
    }
  }
  return (
    <div className={style.progressBar}>
      <div className={style.progressMain}>
        <div className={style.proress} style={{ width: `${getProgress()}%` }}/>
      </div>
      <div className={style.num}>
        <div className={style.left}><span>{ (amount-spareAmount).toFixed(2)}</span>/{amount.toFixed(2)}</div>
        <div className={style.right}>{l.progressBar1} <span>{getProgress()}%</span></div>
      </div>
    </div>
  )
}

export default ProgressBar
