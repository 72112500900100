import React from 'react'
import styles from './null.module.css'
import null_img from './images/shuju.png'
import { getLanguage } from '../../locate'

function Null() {
  const l = getLanguage().All
  return (
    <div className={styles.container}>
      <div>
        <img src={null_img} alt=""/>
      </div>
      <div className={styles.text}>{l.noData}</div>
    </div>
  )
}

export default Null
