import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ToastFail } from '../../../utils/http/err_handle'
import styles from './index.module.scss'
import { getCommonProblemInfo } from '../../../api/commonProblem'
import { getLanguage } from '../../../locate'

interface Info {
  title: string,
  createTime: number,
  content: string
}

function ProblemInfo() {
  const l = getLanguage().All
  let { id } = useParams<any>()
  const [ data, setData ] = useState<Info>({ title: '', createTime: 0, content: '' })
  useEffect(() => {
    document.title = l.problem2
    getCommonProblemInfo(id).then(res => {
      res.data.content=res.data.content?.replace(/http:\/\/xuke1/g,"https://xuke1")
      setData(res.data)
    }).catch(ToastFail)
  }, [])
  return (
    <div className={styles.problemInfo}>
      <div className={styles.title}>{data.title} </div>
      <div className={styles.time}>{data.createTime?moment(data.createTime).format('YYYY-MM-DD HH:mm:ss'):''}</div>
      {/*    dangerouslySetInnerHTML={{ __html: news.content }}*/}
      <div dangerouslySetInnerHTML={{ __html: data.content }} className={styles.content}/>
    </div>
  )
}

export default ProblemInfo
