import { Button, Modal, Toast, Popover } from 'antd-mobile'
import { isEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  goStrategy, lib_apply,
  queryStrategyDetails,
  queryStrategyLibrary,
  startStrategy,
  stopStrategy,
  updateStrategyType,
  getReplaceInfo, replaceConfirm, jumpInfo
} from '../../../api/strategy'
import PullLoadList from '../../../components/pull-load-list/PullLoadList'
import { LoopType } from '../../../lib/enums'
import { getLanguage } from '../../../locate'
import { useQuery } from '../../../utils/custom_hooks'
import { ToastFail } from '../../../utils/http/err_handle'
import native_sdk from '../../../utils/native_sdk'
import { toKeepDecimal } from '../../../utils/tools'
import ChangeToFil from './components/changeToFil'
import ManualCover from './ManualCover'
import NormalCover from './NormalCover'
import ParamDetail from './ParamDetail'
import ParamDetailSecond from './ParamDetailSecond'
import Decimal from 'decimal.js'
import styles from './transaction-detail.module.css'

// import add_img from './images/add.png'
import fold_img from './images/sq.png'
import unfold_img from './images/zk.png'
import edit_img from './images/xiugai.png'
import more from './images/more.png'
import DumpsPositions from './components/dumpsPositions'
import SplitBill from './components/splitBill'

const stop_strategy = (id: string, fn: Function) => {
  const l = getLanguage().All
  Modal.alert(l.transaction58, l.transaction59, [
    {
      text: l.transaction21, onPress: () => {
      }
    },
    {
      text: l.transaction22, onPress: () => {
        stopStrategy(id).then(() => {
          Toast.info(l.transaction60)
          return fn()
        })
          .catch(ToastFail)
      }
    },
  ])
}

const resume_strategy = (id: string, fn: Function) => {
  const l = getLanguage().All
  Modal.alert(l.transaction61, l.transaction62, [
    {
      text: l.transaction21,
    },
    {
      text: l.transaction22,
      onPress: () => {
        goStrategy(id).then(() => {
          Toast.info(l.transaction63)
          return fn()
        }).catch(ToastFail)
      }
    }
  ])
}

const start_strategy = (id: string, symbol: string, fn: Function) => {
  const l = getLanguage().All
  Modal.alert(l.transaction64, l.transaction65, [
    {
      text:l.transaction21,
    },
    {
      text:l.transaction22,
      onPress: () => {
        startStrategy(symbol, id).then(() => {
          Toast.info(l.transaction66)
          return fn()
        }).catch(ToastFail)
      }
    }
  ])
}

function TransactionDetail() {
  // const nav = useLocalObservable(() => ({
  //   y: 0,
  //   setY(y: number) {
  //     this.y = y
  //   }
  // }))
  // const [ y, setY ] = useState(0)
  const [ normalCover, setNormalCover ] = useState(false)
  const [ manualCover, setManualCover ] = useState(false)
  const [ data, setData ] = useState<any>({})
  const [ libs, setLibs ] = useState<any[]>([])
  const [ fold, setFold ] = useState(false)
  const { symbol, id = '0' } = useQuery()
  const [ visible, setVisible ] = useState(false)
  const [ openDump, setOpenDump ] = useState(false)
  const [ openSplit, setOpenSplit ] = useState(false)
  //是否打开置换成fil弹窗
  const [changeToFil,setChangeToFil] =useState(false)
  //置换fil的数据
  const [replaceInfo,setReplaceInfo] =useState<any>({})
  document.title = symbol
  const l = getLanguage().All
  // console.log(l.noData)
  // console.log(l.loop_s)
  // const id = localStorage.getItem(LOCAL_STRATEGY_ID) ?? '0'
  const history = useHistory()
  const init = async () => {
    return Promise.all([
      queryStrategyDetails(Number(id)),
      queryStrategyLibrary({ pageNo: 1, pageSize: 9999 })
    ])
      .then(([ res, res1 ]) => {
        setData(res.data)
        setLibs(res1.data.lists)
      })
      .catch(ToastFail)
  }
  const tryChangeToFil =()=>{
    // setChangeToFil(true)
    getReplaceInfo(id).then(res=>{
      setReplaceInfo(res.data)
    }).then(_=>{
      setChangeToFil(true)
    }).catch(ToastFail)
  }
  const changeConfirm = ()=>{
    Promise.all([replaceConfirm(id),stopStrategy(id)]).then(([res1,res2])=>{
      Toast.info(l.transaction67)
      setChangeToFil(false)
      native_sdk.back()
    }).catch(
      ToastFail
    )
  }
  useEffect(() => {
    let timer: number

    function loop() {
      queryStrategyDetails(Number(id))
        .then(({ data }) => {
          setData(data)
          timer = setTimeout(() => loop(), 2000) as unknown as number
        })
        .catch(ToastFail)
    }

    timer = setTimeout(() => loop(), 2000) as unknown as number
    return () => {
      clearTimeout(timer)
    }
  }, [ id ])
  // const clear_strategy = (id: string) => {

  // }
  let amount = data.first_amount
  const is_show = !isEmpty(data) && data.sourceType !== 1
  return (
    <div className={styles.container}>
      {/*<Observer>
        {() => (
          <NavBar title={symbol} tran={Math.min(Math.max(nav.y, 0), 100)}/>
        )}
      </Observer>*/}
      <div className={styles.content}>
        <PullLoadList
          // onScroll={({ y }) => nav.setY(-y)}
          bounce={{ left: false, right: false, bottom: false, top: true }}
          onReq={async () => {
            await init()
            return []
          }}
          pullUpLoad={false}
        >
          <div className={styles.head_bg}>
            <div className={styles.ticker}>
              {symbol}
              {data.is_have !== 0 && (
                <>
                  <span className={styles.loop}>{data.orderType === LoopType.Single ? l.transaction68 : l.transaction69}</span>
                  <span
                    className={styles.change_loop}
                    onClick={() => {
                      updateStrategyType([ id ], data.orderType === LoopType.Single ? LoopType.Loop : LoopType.Single)
                        .then(() => init())
                        .catch(ToastFail)
                    }}
                  >
                  {data.orderType === LoopType.Single ? l.transaction70: l.transaction71}
                  </span>
                </>
              )}
            </div>
            <div className={styles.state}>
              <div className={styles.state_row}>
                <div className={styles.state_col}>
                  <div>
                    <div className={styles.state_value}>{data.hold_amount}</div>
                    <div className={styles.state_label}>{l.transaction72}(U)</div>
                  </div>
                </div>
                <div className={styles.state_col}>
                  <div>
                    <div
                      className={styles.state_value}>{data.hold_unit > 1 ? toKeepDecimal(data.hold_unit, 4) : data.hold_unit}</div>
                    <div className={styles.state_label}>{l.transaction73}(U)</div>
                  </div>
                </div>
                <div className={styles.state_col}>
                  <div>
                    <div className={styles.state_value}>
                      {typeof data.b_num !== 'undefined' ? data.b_num + 1 : ''}
                    </div>
                    <div className={styles.state_label}>{l.transaction74}</div>
                  </div>
                </div>
              </div>
              <div className={styles.state_row}>
                <div className={styles.state_col}>
                  <div>
                    <div className={styles.state_value}>{toKeepDecimal(data.hold_num || '0', 4)}</div>
                    <div className={styles.state_label}>{l.transaction75}({symbol.split('/')[0]})</div>
                  </div>
                </div>
                <div className={styles.state_col}>
                  <div>
                    <div className={styles.state_value}>{data.current_value}</div>
                    <div className={styles.state_label}>{l.transaction76}(U)</div>
                  </div>
                </div>
                <div className={styles.state_col}>
                  <div>
                    <div className={styles.state_value}>
                      {/*((data.current_value - data.hold_unit) / data.hold_unit) * 100*/}
                      {isEmpty(data) ? 0 : toKeepDecimal(
                        new Decimal(data.current_value)
                          .sub(new Decimal(data.hold_unit))
                          .div(new Decimal(data.hold_unit))
                          .mul(new Decimal(100)).toString(),
                        2)}%
                    </div>
                    <div className={styles.state_label}>{l.transaction77}</div>
                  </div>
                </div>
              </div>
              <div className={styles.state_row}>
                <div className={styles.state_col}>
                  <div>
                    <div className={styles.state_value}>
                      {isEmpty(data) ? 0 : toKeepDecimal(data.hold_unit + data.hold_unit * (data.stop_pi / 100), 4)}
                    </div>
                    <div className={styles.state_label}>{l.transaction78}(U)</div>
                  </div>
                </div>
                <div className={styles.state_col}>
                  <div>
                    <div className={styles.state_value}>
                      {!isEmpty(data) && data.b_num + 1 < data.params.length ?
                        toKeepDecimal(data.hold_unit - data.hold_unit * (data.params[data.b_num + 1].down_pi / 100), 4) : 0}
                    </div>
                    <div className={styles.state_label}>{l.transaction79}(U)</div>
                  </div>
                </div>
                <div className={styles.state_col}>
                  <div>
                    <div className={styles.state_value}>
                      {isEmpty(data) ? 0 : toKeepDecimal(new Decimal(data.current_value)
                        .sub(new Decimal(data.hold_unit))
                        .div(new Decimal(data.hold_unit)).mul(data.hold_amount).toString(), 4)}
                    </div>
                    <div className={styles.state_label}>{l.transaction80}(U)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.detail}>
            <div className={styles.nav}>
              <div className={styles.nav_row}>
                <div className={styles.nav_title}>
                  {l.transaction81}
                </div>
                {is_show && <div onClick={() => {
                  if (data.state === 1) {
                    return Toast.fail(l.transaction82)
                  }
                  Modal.alert(l.transaction83, l.transaction84, [
                    {
                      text:l.transaction21, onPress: () => {
                      }
                    },
                    {
                      text: l.transaction85, onPress: () => {
                        native_sdk.jump(id)
                      }
                    },
                  ])
                  // history.push({
                  //   pathname: '/transaction/strategy',
                  //   search: `?id=${id}&symbol=${symbol}`,
                  //   state: data.is_have ? data : undefined,
                  // })
                }} className={styles.modify_btn}>
                  {data.is_have ? <img src={edit_img} alt=""/> : null}
                  {data.is_have ? l.transaction86 : l.transaction87}
                </div>}
              </div>
              <div className={styles.nav_line}/>
            </div>
            {typeof data.state === 'undefined' ? null : is_show ? (
              <>
                <div>
                  <ParamDetail is_show={is_show} data={data}/>
                  {!fold && data.params?.slice(1).map((v: any, k: number) => (
                    <ParamDetailSecond is_show={is_show} data={v} key={k} amount={amount *= v.bt_pi}/>
                  ))}
                </div>
                {data.params?.length > 1 && (
                  <div onClick={() => setFold(!fold)} className={styles.fold}>
                    {fold ? l.transaction88 : l.transaction89} <img src={fold ? unfold_img : fold_img} alt=""/>
                  </div>
                )}
              </>
            ) : (
              <div className={styles.hideTip}>
                <div className={styles.tip1}>
                  <p>{'\u3000\u3000'}{l.transaction90}</p>
                  <p>{'\u3000\u3000'}{l.transaction91}</p>
                </div>
                <div className={styles.tip2}>
                  <div className={styles.tip2Title}><img src={require('./images/tips.png').default}
                                                         alt=""/><span>{l.transaction92}</span></div>
                  <div className={styles.tip2Content}>
                    <p>{'\u3000\u3000'}1.{l.transaction93}</p>
                    <p>{'\u3000\u3000'}2.{l.transaction94}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.line}/>
          {libs.length && is_show ? (
            <div className={styles.strategy_list}>
              <div className={styles.nav}>
                <div className={styles.nav_row}>
                  <div className={styles.nav_title}>
                    {l.transaction95}
                  </div>
                </div>
                <div className={styles.nav_line}/>
              </div>
              <div className={styles.list}>
                {libs.map((v, i) => (
                  <div key={i} className={styles.strategy_item}>
                    <div className={styles.strategy_name}>
                      {i + 1}.{v.name}
                    </div>
                    <Button onClick={() => {
                      lib_apply(id, String(v.id))
                        .then(() => init())
                        .then(() => {
                          Toast.info(l.transaction96)
                        })
                        .catch(ToastFail)
                    }} className={styles.strategy_btn}>
                      {l.transaction97}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </PullLoadList>
      </div>
      <div className={styles.operation}>
        {typeof data.state === 'undefined' ? (
          <p className={styles.loading_text}>. . .</p>
        ) : (
          <>
            <Button
              className={styles.left_btn}
              onClick={() => {
                if (data.state === 0 || data.state === 3) {
                  start_strategy(id, symbol, init)
                } else if (data.state === 1) {
                  stop_strategy(id, init)
                } else {
                  resume_strategy(id, init)
                }
              }}
              // className={styles.left_btn}
              // onClick={() => clear_strategy(id)}
            >
              {(data.state === 0 || data.state === 3) ? l.transaction98 : data.state === 1 ? l.transaction99 : l.transaction100}
            </Button>
            <Button
              disabled={!is_show && data.manual_replenishment_status === 1 && data.b_num < data.params.length - 1}
              onClick={() => {
                if (data.state === 1) {
                  setManualCover(true)
                } else {
                  Toast.fail(l.transaction101)
                }
              }}
              className={styles.right_btn}
            >
              {l.transaction102}
            </Button>
            <Popover
              mask={true}
              placement="topRight"
              visible={visible}
              onVisibleChange={(e) => {
                setVisible(e)
              }}
              onSelect={(e) => {
                setVisible(false)
                switch (e.key) {
                  case '0':
                    tryChangeToFil()
                    break
                  case '1':
                    setOpenDump(true)
                    break
                  case '2':
                    setOpenSplit(true)
                    break
                  // case '3':
                  //   // setOpenSplit(true)
                  //   if(data.state === 2 || data.state === 4){
                  //     native_sdk.order(id)
                  //   }else{
                  //     Toast.fail(l.transaction103)
                  //   }
                  //   break
                }
              }}
              overlay={
                data.sourceType !== 5 && data.sourceType !== 6 ? [
                // <Popover.Item key="3">{l.transaction104}</Popover.Item>,
                <Popover.Item key="0">{l.transaction105}</Popover.Item>,
                <Popover.Item key="1">{l.transaction106}</Popover.Item>,
                <Popover.Item key="2">{l.transaction107}</Popover.Item>,

              ]:[
                <Popover.Item key="1">{l.transaction108}</Popover.Item>,
                ]}>
              <div
                className={styles.clear_btn}
              >
                <img src={more} alt=""/>
                <span>
                {l.transaction109}
              </span>
              </div>
            </Popover>
          </>
        )}
      </div>
      <NormalCover
        show={normalCover}
        onClose={() => setNormalCover(false)}
        onConfirm={value => {
          setNormalCover(false)
          console.log(value)
        }}
      />
      {typeof data.state !== 'undefined' ? (
        <ManualCover
          profit_per={data.stop_pi}
          hold_unit={data.hold_unit}
          hold_amount={data.hold_amount}
          current_price={data.ask}
          hold_num={data.hold_num}
          id={id}
          show={manualCover}
          onClose={() => setManualCover(false)}
        />
      ) : null}
      {openDump ? <DumpsPositions
        visible={openDump}
        setVisible={setOpenDump}
        symbol={symbol}
        holdAmount={data.hold_amount}
        holdNum={data.hold_num}
        strategyId={id}
      /> : <></>}
      {
        openSplit ? <SplitBill
          visible={openSplit}
          setVisible={setOpenSplit}
          symbol={symbol}
          holdAmount={data.hold_amount}
          holdNum={data.hold_num}
          strategyId={id}
        /> : <></>
      }
      {
        changeToFil?<ChangeToFil
          onCancel={()=>{setChangeToFil(false)}}
          onConfirm={()=>{changeConfirm()}}
          replaceInfo={replaceInfo}
          toProblem={()=>{
            jumpInfo().then(res=>{
              history.push(`/problem/info/${res.data}`)
            }).catch(ToastFail)
          }}
        />:<></>
      }
    </div>
  )
}

export default TransactionDetail
