import Decimal from 'decimal.js'
import React, { useState } from 'react'
import { Modal, Toast } from 'antd-mobile'
// @ts-ignore
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { ToastFail } from '../../../../utils/http/err_handle'
import { toKeepDecimal } from '../../../../utils/tools'
import { AmountVerification } from '../../../../utils/verification/form-verification'
import styles from './dumpsPositions.module.scss'
import { reduceStock } from '../../../../api/strategy'
import native_sdk from '../../../../utils/native_sdk'
import { getLanguage } from '../../../../locate'
function DumpsPositions(props: any) {
  const l = getLanguage().All
  let { visible, setVisible, holdAmount, symbol, holdNum, strategyId } = props
  const [ dumpVal, setDumpVal ] = useState<any>('')
  const [ dumpNum, setDumpNum ] = useState<any>('0.0000')
  const [ strategyPercent, setStrategyPercent ] = useState(0)
  const handle = (props: any) => {
    const { value, dragging, index, ...restProps } = props
    return <Slider.Handle value={value} {...restProps}>
      <img src={require('../images/huakuai.png').default} className={styles.huakuai}/>
    </Slider.Handle>
  }
  const marks = { 0: <></>, 20: <></>, 40: <></>, 60: <></>, 80: <></>, 100: <></> }
  const onConfirm = () => {
    if (Number(dumpVal) < 15 && Number(dumpVal) !== Number(holdAmount)) {
      return Toast.info(l.transaction11, 2)
    }
    if (Number(holdAmount) - Number(dumpVal) < 15 && Number(dumpVal) !== Number(holdAmount)) {
      return Toast.info(l.transaction12, 2)
    }
    reduceStock({
      strategyAmountStr: dumpNum,
      strategyPercent: strategyPercent + '',
      strategyId: Number(strategyId)
    }).then(res => {
      Toast.success(l.transaction13, 2, () => {
        setVisible(false)
        if (Number(dumpVal) === Number(holdAmount)) native_sdk.back()
      })
    }).catch(ToastFail)
  }
  return (
    <Modal
      popup
      className={styles.dumpsPositions}
      visible={visible}
      onClose={() => setVisible(false)}
      animationType="slide-up">
      <div className={styles.content}>
        <div className={styles.cion}>
          <span>{symbol}</span>
          <span>{l.transaction14}{holdAmount}</span>
        </div>
        <div className={styles.slider}>
          <Slider
            marks={marks}
            railStyle={{ backgroundColor: '#fff', height: 4 }}
            trackStyle={{ backgroundColor: '#2A6AE7', height: 4 }}
            dotStyle={{ backgroundColor: '#EBECF0', height: 11, width: 6, borderRadius: 0, bottom: -4 }}
            activeDotStyle={{ backgroundColor: '#2A6AE7' }}
            handle={handle}
            onChange={(e: any) => {
              setStrategyPercent(e)
              let val: any = new Decimal(holdAmount).mul(e).div(100)
              val = toKeepDecimal(Number(val) || '0', 4)
              setDumpVal(val)
              let num: any = new Decimal(holdNum).mul(e).div(100)
              num = toKeepDecimal(Number(num) || '0', 4)
              setDumpNum(num)
            }}/>
        </div>

        <div className={styles.inputBox}>
          <div className={styles.inputTittle}>
            {l.transaction15}(USDT）
          </div>
          <div className={styles.input}>
            <input type="text" value={dumpVal} onChange={(e) => {
              let { value } = e.currentTarget
              value = value === '' ? value : AmountVerification(value) || dumpVal
              setDumpVal(value)
              if (value) {
                let bl: any = new Decimal(value).div(Number(holdAmount))
                let dump: any = bl.mul(holdNum)
                dump = toKeepDecimal(Number(dump) || '0', 4)
                setDumpNum(dump)
              } else {
                setDumpNum(toKeepDecimal('0', 4))
              }
            }}/>
          </div>
        </div>
        <div className={styles.inputBox}>
          <div className={styles.inputTittle}>
            {l.transaction16}({symbol.split('/')[0]})
          </div>
          <div className={styles.input}>
            <input type="text" value={dumpNum} readOnly/>
          </div>
        </div>
        <div className={styles.btns}>
          <div className={styles.btn} onClick={onConfirm}>{l.transaction17}</div>
        </div>
      </div>
    </Modal>
  )
}

export default DumpsPositions
