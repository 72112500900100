import { Toast } from 'antd-mobile'
import Decimal from 'decimal.js'
import { action } from 'mobx'
import { useLocalObservable, Observer } from 'mobx-react-lite'
import { clone } from 'ramda'
import React, { FormEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getUserAssets, setFilWithdraw } from '../../../api/lease'
import Popup from '../../../components/popup/Popup'
import encrypt from '../../../utils/encrypt'
import { ToastFail } from '../../../utils/http/err_handle'
import { throttle } from '../../../utils/tools'
import { AmountVerification, English } from '../../../utils/verification/form-verification'
import TextField from './components/TextField'
import styles from './index.module.scss'
import { getLanguage } from '../../../locate'

const initialFormState = {
  num: '0',
  address: '',
  password: ''
}
interface Assets {
  withdrawServiceChargePi: number,
  withdrawMinAmount: number,
  withdrawMultiple: number,
  withdrawServiceChargeMinAmount: number,
  withdrawServiceChargeMaxAmount:number
}
function FilTb() {
  const l = getLanguage().All
  const data = useLocalObservable(() => ({
    value: clone({...initialFormState, address: sessionStorage.getItem('address') || ''}),
    all: 0,
    setValue(key: keyof typeof initialFormState, val: string) {
      this.value[key] = val;
    },
    clearValue() {
      this.value = clone(initialFormState)
    },
    setAll(val: number) {
      this.all = val
    }
  }), {setValue: action, setAll: action, clearValue: action})

  const handleChange = (key: keyof typeof initialFormState, verification: Function = (text: string) => text) => (event: FormEvent<HTMLInputElement> | string) => {
    let value = typeof event === 'string' ? event : event.currentTarget.value;
    data.setValue(key, value === '' ? ''
      : (verification(value) || data.value[key]) as any)
  }

  const form = data.value;

  const [sucLog, setSucLog] = useState(false);

  const history = useHistory()
  const [ assets, setAssets ] = useState<Assets>({ withdrawServiceChargePi: 0, withdrawMinAmount: 0, withdrawMultiple: 0, withdrawServiceChargeMinAmount: 0,withdrawServiceChargeMaxAmount:0 })
  useEffect(() => {
    document.title = l.fil_tb1
    getUserAssets().then((res) => {
      data.setAll(res.data.filBalance)
      setAssets(res.data)
    }).catch(ToastFail)
  }, [data] )


  const handleSubmit = () => {
    if (form.num === '') return Toast.fail(l.fil_tb2);
    if (form.password === '') return Toast.fail(l.fil_tb3);
    if (form.address === '') return Toast.fail(l.fil_tb4);
    setFilWithdraw({
      amount: Number(form.num),
      payPwd: encrypt.encrypt(form.password),
      extractAddress: form.address,
    }).then(() => {
      data.clearValue();
      history.goBack();
      setSucLog(true)
    }).catch(ToastFail)
  }
  const handleToAddress=()=>{
    history.push('/lease/fil_addressList')
  }

  return (
      <Observer>
        {() => {
          let {withdrawServiceChargeMaxAmount:max,withdrawServiceChargeMinAmount:min}=assets
          let fee: Decimal | number = new Decimal(Number(form.num)).mul(assets.withdrawServiceChargePi);
          fee = fee.gt(min) && fee.lt(max) ? fee: fee.lt(min) ?min:max ;

          return (
            <div className={styles.main}>
              <div className={styles.scroll}>
                <div className={styles.currency}>FIL-Filecoin</div>
                <div className={styles.textField}>
                  <TextField
                    onChange={handleChange('num', AmountVerification)}
                    value={form.num}
                    label={l.fil_tb5}
                    placeholder={l.fil_tb6}
                    unit="FIL"
                    onAll={() => {
                      handleChange('num')(String(data.all))
                    }}
                  />
                </div>
                <div className={styles.available}>
                  可用 {data.all}   FIL
                </div>
                <div className={styles.textField}>
                  <TextField
                    value={fee.toString()}
                    label={l.fil_tb7}
                    unit="FIL"
                    readOnly
                  />
                </div>
                <div className={styles.textField}>
                  <TextField
                    onChange={handleChange('address',English)}
                    value={form.address}
                    label={l.fil_tb8}
                    placeholder={l.fil_tb9}
                    toAddress={handleToAddress}
                    icon={require('../imgs/address.png').default}
                  />
                </div>
                <div className={styles.tips}>
                  <p>{l.fil_tb10}：</p>
                  <p>1、{l.fil_tb11}{assets.withdrawMinAmount}{l.fil_tb12}，{l.fil_tb13}{assets.withdrawMultiple}{l.fil_tb14}</p>
                  <p>2、{l.fil_tb15}{(assets.withdrawServiceChargePi*100).toFixed(2)}%（{l.fil_tb16}）, {l.fil_tb17}{assets.withdrawServiceChargeMinAmount}FIL</p>
                  <p>3、{l.fil_tb18}</p>
                  <p>4、{l.fil_tb19}</p>
                  <p>5、{l.fil_tb20}</p>
                </div>
              </div>
              <footer className={styles.footer}>
                <div className={styles.real}>
                  {l.fil_tb21}{'\u3000'}{Decimal.max(new Decimal(Number(form.num)).sub(fee), 0).toString()}
                </div>
                <div className={styles.submit}>
                  <div className={styles.inp}>
                    <input type="password" value={form.password} onChange={handleChange('password')} placeholder={l.productInfo34} />
                  </div>
                  <div className={styles.btn}>
                    <button onClick={throttle(handleSubmit)}>{l.fil_tb22}</button>
                  </div>
                </div>
              </footer>
              <Popup show={sucLog} onClose={() => setSucLog(false)}>
                <div className={styles.dialog}>
                  <div className={styles.dialogTitle}>
                    {l.fil_tb23}
                  </div>
                  <div className={styles.dialogContent}>
                    {l.fil_tb24}
                  </div>
                  <div onClick={() => {
                    setSucLog(false)
                    window.history.go(-1)
                  }} className={styles.dialogBtn}>
                    {l.fil_tb25}
                  </div>
                </div>
              </Popup>
            </div>
          )
        }}
      </Observer>
  )
}

export default FilTb
