export default class zh_CN {
  static TransactionDetail = {
    loop_s: '循环策略',
    single_s: '单次策略',
    change_loop: '切换至循环策略',
    change_single: '切换至单次策略',
    hold_amount: '持仓金额',
    hold_unit: '持仓均价',
    current_cover: '当前手数',
    hold_num: '持仓数量',
    current_price: '当前价格',
    aoi: '涨幅',
    detail: '策略详情',
    add: '添加策略',
    modify: '修改策略',
    first_amount: '首单额度',
    bt_num: '倍投手数',
    dj: '第{0}手',
    zy: '止盈比例',
    yht: '盈利回调',
    bc_df: '补仓跌幅',
    bt_bl: '倍投比例',
    b: '倍',
    bc_ht: '补仓回调',
    zk: '点击展开',
    sq: '点击收起',
    stop: '停止策略',
    resume: '继续策略',
    run: '启动策略',
    manual_cover: '手动补仓',
    bc_amount: '补仓金额',
    inp_bc_amount: '请输入补仓金额',
    current_unit: '当前均价',
    after_unit: '补仓后均价',
    after_aoi: '补仓后涨幅',
    after_zy: '补仓后止盈价',
    cancel: '取消',
    confirm: '确认',
    clear_cover: '清仓卖出',
    clear_title: '是否确认清仓卖出？',
    clear_content: '系统将您的持仓全部按火币市价卖出',
    s_s: '策略选择',
    one_click: '一键应用',
    apply_success: '应用成功！',
    submit_suc: '提交成功！',
    stop_title: '是否停止该策略？',
    stop_content: '系统将会暂停您的策略',
    stop_suc: '停止成功',
    resume_title: '是否继续策略？',
    resume_content: '系统将会继续您的策略运行',
    resume_suc: '已恢复运行',
    run_title: '提示',
    run_content: '是否开始该策略',
    run_suc: '策略开始',
    tip1: '订单启动中无法修改，请先停止策略',
    tip2: '订单停止中无法手动补仓，请先启动策略',
    tip3: '补仓金额必须为大于等于5的数字',
    fk: '浮亏',
    zy_price: '止盈价格',
    next_bp: '下手补仓价格',
    shopCelue:'请先停止策略',
    orderChange:'订单转移',
    moreCaozuo:'更多操作'
  };
  static Error = {
    network_error: '服务器繁忙\n请稍后重试',
    timout_error: '网络连接失败，请稍后重试',
  };
  static All = {
    /* Null.tsx */
    noData: '暂无数据',
    /* PullDown.tsx */
    free_to_refresh: '释放即可刷新',
    pull_to_refresh: '下拉刷新',
    loading: '加载中...',
    loadComplete: '加载完成',
    /* PullLoadList.tsx */
    noMoreData: '没有更多了~',
    pull_up_to_load_more:'上拉加载更多',
    /* add-lot.tsx */
    update_success:'更新成功',
    add_Hand_Number:'添加手数',
    first_One: '第1手',
    first_limit: '首单额度',
    check_surplus_proportion:'止盈比例',
    add_lot1:'盈利回调',
    add_lot2:'补仓跌幅',
    add_lot3:'补仓回调',
    add_lot4:'倍投比例',
    add_lot5:'补仓跌幅',
    add_lot6:'确定',
    add_lot7:'添加下一手',
    /* problem.tsx */
    problem1:'常见问题',
    problem2:'详情',
    /* address.tsx */
    address1:'编辑',
    address2:'编辑FIL地址',
    address3:'地址',
    address4:'请输入FIL地址',
    address5:'备注',
    address6:'请输入备注',
    address7:'确定',
    address8:'FIL地址',
    address9:'删除成功！',
    address10:'添加地址',
    address11:'添加FIL地址',
    address12:'地址不能为空!',
    address13:'备注不能为空!',
    address14:'新增成功！',
    /* agreement.tsx */
    agreement1:'用户服务协议v1.0',
    agreement2:'Glutton软件APP（以下简称“Glutton”）推出 租赁宝 服务，为Glutton用户（以下简称“您”）提供闲置数字资产增值服务。除Glutton另有规定外，经完成Glutton账户注册及认证手续后，您同样需要另行与Glutton缔结《 租赁宝 服务使用协议》并开设专项账户及/或完成其他相关手续，方可使用 租赁宝 服务(下称：本产品)。在使用该产品服务前，您知晓并同意：',
    agreement3:'Glutton为特定币种挖矿提供流动性时，用户添加的资产可以享受对应币种的活期利息，即用户按照产品服务页面规则选定购买的产品类型，在专项账户内存入一定数量对应币种的数字资产，存储期限届满后，用户即可取出原存储数量数字资产，以及存储期间产生的利息。',
    agreement4:'您在申购本产品前，必须确认币对的单价与所需支付的手续费，一旦发出交易指令后，用户的数字货币资产将与Glutton的 租赁宝 产品资产执行交换（"交易"）。交易执行后，用户的账户将被更新。为了达成交易，用户授权Glutton暂时控制在交易中处置的数字货币;',
    agreement5:'用户同意向Glutton支付因交易而产生的费用（手续费），Glutton可自行决定随时更新该费用。任何更新费用将适用于更新费用生效日期之后发生的任何买卖或其他交易。用户授权Glutton从用户的账户中扣除根据本使用条款所欠的任何适用费用。',
    agreement6:'用户在使用本产品服务时，即无条件授权Glutton将收取的手续费、利息等按照平台的规则进行合理的分配和发放。',
    agreement7:'Glutton为了更好的为用户提供闲置数字资产增值服务，用户了解并授权Glutton暂时控制在购买本服务时所处置的数字货币资产。',
    agreement8:'当币对在市场上发生剧烈波动时，本产品的份额持有者可能无法享有同样的价值增益，因此产品申购行为不是一种无风险的操作，享受的并非保本收益。',
    agreement9:'用户使用本产品服务时，应当遵守国家相关法律，保证资产的来源合法合规；应当充分认识到数字资产投资的风险，谨慎操作，量力而行。',
    agreement10:'用户同意在Glutton所进行的所有投资操作代表其真实投资意愿，并无条件接受投资决策所带来的潜在风险和收益。',
    agreement11:'Glutton保留暂停、终止 租赁宝 服务的权限，在必要的时候，Glutton平台可以随时暂停，终止 租赁宝 服务。',
    agreement12:'由于网络延迟，计算机系统故障及其他可能的不可抗拒因素，可能导致 租赁宝 服务的执行迟缓，暂停，中止或偏差，Glutton将尽力保证但不承诺 租赁宝 服务执行系统运行的稳定和有效，但由于上述因素导致的 租赁宝 服务的最终执行结果与用户预期不同，Glutton不承担任何责任。',
    agreement13:'我已阅读并同意 租赁宝 服务购买协议，并同意购买 租赁宝 业务。我已知晓此风险并确认购买。',
    /* userAgreement.tsx */
    user_agreement1:'Glutton用户协议',
    user_agreement2:'1 免责声明',
    user_agreement3:'1.1 为使用本软件及服务，您应当阅读并遵守《免责声明及使用协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解本协议各条款内容。您可以选择不使用Glutton app，但如果您使用Glutton app，您的使用行为将被视为对本协议全部内容的认可。',
    user_agreement4:'1.2 除非您已阅读并接受本协议所有条款，否则您无权下载、安装或使用Glutton app的软件及相关网站提供的服务(以下简称“本系统”)。您的下载、安装、使用、获取账号、登录等行为即视为您已阅读并同意本协议的约束。',
    user_agreement5:'1.3 软件著作权人及所有权人Glutton app量化投资团队(以下简称“本团队”）本着负责任的态度制定本协议。若您需要获得本服务，您（以下称"用户"或"使用者"）应当同意本协议的全部条款，并按照要求完成全部的申请程序。',
    user_agreement6:'2 协议',
    user_agreement7:'2.1 权利声明',
    user_agreement8:'本系统所有功能/源码仅供内部技术交流,严禁非法用途，严禁利用本软件从事配资、诈骗等非法经济活动，倘若将该软件用于非法用途，造成受害人经济等方面损失，甚至构成违法犯罪的，均与本团队无关，用户自行承担法律责任。本团队将及时向有关部门报案并积极配合提供违法犯罪人员的信息，并保留对违法犯罪人员进一步追究利用本软件进行违法犯罪行为的权利。',
    user_agreement9:'本系统已申请软件著作权，本团队对系统所有功能/源码享有包括著作权、知识产权等一切权利，未经本团队同意，严禁用于商业用途，严禁进行对本软件进行二次开发和任意改造进行传播。任何对本软件进行的修改或二次开发，均视为对本软件的侵权行为，本团队将保留追究的权利。若使用本软件或对本软件进行修改以从事其他交易活动、诈骗等犯罪行为，均与本团队无关，本团队届时有义务配合有关部门提供相应的使用者信息以供调查。',
    user_agreement10:'2.2 服务内容与授权使用范围',
    user_agreement11:'本系统主要提供给投资者一个学习交流量化的平台及工具，使用者可自己实现编写交易策略以达到量化交易目的。凡是用户在本系统上自行编写的交易策略源码，归属用户所有，用户可以对自己的策略进行修改及调试。',
    user_agreement12:'本系统主要提供一个量化策略编写及量化交易实践环境，使用者可自己实现交易策略以达到量化交易目的，用户自行编写的交易策略归属用户所有。本团队后续也将提供部分策略服务，本团队提供的策略属于定制的特殊服务，为用户可选的有偿服务，倘若定制策略，会收取一定的知识劳务费，请用户知悉。',
    user_agreement13:'本系统的智能下单功能模块，有以下几种方式：',
    user_agreement14:'1) 使用类按键精灵的技术或利用图像识别等控件定位技术，利用所见所得，代替使用者用鼠标或键盘进行模拟人的操作进行智能下单，不对第三方软件进行拆包或解包等任何破坏行为，不侵入不破坏第三方软件的运行逻辑。',
    user_agreement15:'2) 与本团队合作的券商，利用券商提供的授权接口，采用不限于HTTP传输等方式进行接口调用执行远程下单。调用的接口为合作方券商提供给本团队使用的接口，所有合作事宜均与合作方达成协议，依法依规保障用户的账户安全及一切合法权益。',
    user_agreement16:'3) 本系统的下单指令调用接口，使用上期所的开放CTP技术以及经过期货公司授权的合法CTP接口进行下单。',
    user_agreement17:'以上方法均基于合法合规的途径进行，若用户认为以上有任一种方式有不妥之处，应当立即停止使用，否则视为用户理解并接受。凡是私自改造本软件，造成对第三方软件的破坏行为，造成用户遭受损失或损害本团队利益的，造成违法犯罪的，违法者需自行承担相应后果，我们将保留进一步追究相关法律的权利。',
    user_agreement18:'2.2 用户权利保护及使用规则',
    user_agreement19:'本系统的填写账号密码模块为可选操作，用户可选择使用。若用户选择自动登录，那么所填有关登录涉密信息内容均加密存储并且仅保存本地，本系统不会获取任何有关用户信息。本系统承诺保证不含任何计算机病毒，但不排除本软件系统被所在的用户操作系统存在的不仅限于病毒等手段被攻破，由此导致的用户信息泄露的，均已本团队无关，建议用户查杀计算机系统病毒维护安全上网环境。请根据本网站提供的系统软件下载地址下载软件，请勿随意从其他地方下载，否则用户承担因此引起的不良后果。',
    user_agreement20:'本系统不以盈利为目的，仅提供给粉丝朋友一个交流学习量化的契机，实行严格的一人一码的使用，对使用申请者进行严格登记。为了保护您自身的权利，请勿将获得的Token码随意借给他人使用，否则造成系统及策略运行不正常的由用户自行承担后果。',
    user_agreement21:'本软件部分接口是通过第三方数据平台提供商购买并授权使用，存在一定的成本，并且为确保软件的稳定运行及bug的及时修复，以便更好服务用户，本软件使用积分制或系统维护费进行使用。用户可以通过多种方式和途径获得积分，其中通过捐助获得积分的捐助款部分，以及系统维护费我们将合理分配用于系统的维护等支出。',
    user_agreement22:'Glutton app不保证服务一定会满足用户的使用要求，也不保证服务不会被中断，对服务的稳定性、安全性、准确性也不作担保。如系统遇到被要求停止服务或本团队无力支撑系统继续运行下去的情况，本团队可随时中断系统的服务，用户所获得的积分将作销毁处理，不作退还。',
    user_agreement23:'2.3 风险告示',
    user_agreement24:'在使用本系统时，您充分意识到，市场投资本身存风险，由于金融市场存在波动性，投资者面临市场风险。',
    user_agreement25:'本团队基于本软件提供自动交易模块的技术，不对指令下发所产生的一切盈亏结果负责。凡基于本系统的基础上，采用本系统使用任何交易策略（包括自研或他人提供的策略）发出的任何交易指令，均视为用户亲自下单操作行为，由此造成亏损的均由用户自行承当一切后果。',
    user_agreement26:'使用者务必知悉，Glutton app团队会尽力维护软件稳定运行，但基于程序代码难免存在bug或用户策略代码程序的不可控性或网络的阻塞等产生的交易指令下发失败，或指令下发错误等情况，由此造成的实际盈亏等行为，均由使用者本人承担一切后果。',
    user_agreement27:'2.3 其他说明',
    user_agreement28:'Glutton app团队有权随时对本协议进行修改，发生条款的变动，我们将在页面上提示相关的修改内容。',
    user_agreement29:'若本系统软件存在侵犯您的权益情况，请及时提供您的权属证明及详细情况说明，并联系客服进行反馈，我们将迅速核实并删除有关侵犯您权利的不妥之处。',
    user_agreement30:'对于本协议未明确或遗漏的条款，Glutton app量化团队享有最终解释权。',

    /* assetsDetails.tsx */
    assetsDetails1:'全部',
    assetsDetails2:'充币',
    assetsDetails3:'提币',
    assetsDetails4:'提币退还',
    assetsDetails5:'存储收益',
    assetsDetails6:'本金退还',
    assetsDetails7:'存储',
    assetsDetails8:'推广奖励',
    assetsDetails9:'资产明细',
    /* progressBar.tsx */
    progressBar1:'已售',
    /* fil_tb.tsx */
    fil_tb1:'FIL提币',
    fil_tb2:'请输入提币数量',
    fil_tb3:'请输入交易密码',
    fil_tb4:'请输入提币地址',
    fil_tb5:'提币数量',
    fil_tb6:'请输入提币数量',
    fil_tb7:'手续费',
    fil_tb8:'提币地址',
    fil_tb9:'请输入或长按粘贴地址',
    fil_tb10:'特别提示',
    fil_tb11:'提币',
    fil_tb12:'起',
    fil_tb13:'必须是',
    fil_tb14:'的整数倍。',
    fil_tb15:'提币手续费为',
    fil_tb16:'提币扣除',
    fil_tb17:'手续费最低额度为',
    fil_tb18:'提币后，租赁宝剩余额度，请以页面提示为准。',
    fil_tb19:'提币到账时间为T+1工作日。',
    fil_tb20:'请输入FIL-Filecoin地址。',
    fil_tb21:'实际到账',
    fil_tb22:'确认提币',
    fil_tb23:'提示',
    fil_tb24:'提币申请成功！',
    fil_tb25:'确定',
    /* home.tsx */
    home1:"租赁宝",
    home2:"可用余额",
    home3:"冻结金额",
    home4:"累计收益",
    home5:"充币",
    home6:"提币",
    home7:"已购产品",
    home8:"预发布",
    home9:"申购中",
    home10:"已售罄",
    home11:"封装期",
    home12:"收益期",
    home13:"已完结",
    home14:"总资产折合",
    home15:"明细",
    home16:"定期产品",
    home17:"预估月化收益",
    home18:"存储周期",
    home19:"申购开始时间",
    home20:"申购结束时间：",
    home21:"收益开始时间：",
    /* myProduct.tsx */
    myProduct1:'我的产品',
    myProduct2:'预发布',
    myProduct3:'申购中',
    myProduct4:'已售罄',
    myProduct5:'封装期',
    myProduct6:'收益期',
    myProduct7:'已完结',
    myProduct8:'购买额度',
    myProduct9:'天',
    myProduct10:'预估月化收益',
    myProduct11:'剩余天数',
    myProduct12:'累计收益',
    myProduct13:'冻结本金',
    /* orderInfo.tsx */
    orderInfo1:'订单详情',
    orderInfo2:'预估月化收益',
    orderInfo3:'剩余天数（天)',
    orderInfo4:'累计收益',
    orderInfo5:'冻结本金',
    orderInfo6:'结算周期（天）',
    orderInfo7:'申购开始时间',
    orderInfo8:'申购截止时间',
    orderInfo9:'收益开始时间',
    orderInfo10:'下次收益时间',
    orderInfo11:'购买额度',
    orderInfo12:'说明',
    orderInfo13:'结算日结算收益。',
    orderInfo14:'先息后本型产品，每月结算收益，冻结金额产品到期后在结算日统一退回。等本等息产品，每月结算收益，并退还部分本金。',
    orderInfo15:'预估月化收益仅按照历史记录进行预约，实际收益以最终产出的效率为准。',
    orderInfo16:'FIL日产币数量会随着FIL总算力增加而逐渐减少。',
    orderInfo17:'买入起始额度',
    orderInfo18:'结算记录',
    /* productInfo.tsx */
    productInfo1:'产品详情',
    productInfo2:'预估月化收益',
    productInfo3:'存储周期（天）',
    productInfo4:'结算周期（天）',
    productInfo5:'起购额度（FIL）',
    productInfo6:'申购开始时间',
    productInfo7:'申购截止时间',
    productInfo8:'收益开始时间',
    productInfo9:'请输入买入数量',
    productInfo10:'请勾选用户协议',
    productInfo11:'预发布',
    productInfo12:'申购中',
    productInfo13:'已售罄',
    productInfo14:'封装期',
    productInfo15:'收益期',
    productInfo16:'已完结',
    productInfo17:'买入数量',
    productInfo18:'当前产品剩余',
    productInfo19:'全部',
    productInfo20:'可用',
    productInfo21:'说明',
    productInfo22:'结算日结算收益。',
    productInfo23:'先息后本型产品，每月结算收益，冻结金额产品到期后在结算日统一退回。等本等息产品，每月结算收益，并退还部分本金。',
    productInfo24:'预估月化收益仅按照历史记录进行预约，实际收益以最终产出收益的效率为准。',
    productInfo25:'FIL日产币数量会随着FIL总算力增加而逐渐减少。',
    productInfo26:'买入起始额度',
    productInfo26_1:'点击同意',
    productInfo27:'<<用户服务协议>>',
    productInfo28:'立即购买',
    productInfo29:'提示',
    productInfo30:'恭喜您，购买成功！',
    productInfo31:'确认',
    productInfo32:'您所申购的产品将从',
    productInfo33:'开始计算收益！',
    productInfo34:'请输入交易密码',
    productInfo34_1:'交易密码',
    /* recharge.tsx */
    recharge1:'充值',
    recharge2:'复制成功！',
    recharge3:'充币地址',
    recharge4:'复制地址',
    recharge5:'特别提示',
    recharge6:'需要120个网络确认才能到账，任何非FIL-Filecoin资产充值到FIL-Filecoin地址后不可找回，由此造成的损失与本软件无关，请您在充值前仔细核对充值的资产类型和额度。',
    recharge7:'本软件目前不支持打包充值的方法，使用打包充值将暂时无法上账，请您谅解。',
    /* login.tsx */
    login1:'登录页',
    /* setDeal.tsx */
    setDeal1:'普通策略',
    setDeal2:'进阶策略',
    setDeal3:'策略库',
    setDeal4:'币种交易设置',
    setDeal5:'添加成功！',
    setDeal6:'更新成功！',
    setDeal7:'策略名称',
    setDeal8:'请输入策略名称(选填)',
    setDeal8_1:'日夜模式',
    setDeal9:'日间策略',
    setDeal10:'夜间策略',
    setDeal11:'首单额度',
    setDeal12:'做单数量(单)',
    setDeal13:'按照单位、本金、交易货币计算得出首单买入金额',
    setDeal14:'止盈比例',
    setDeal15:'止盈回调',
    setDeal16:'补仓跌幅',
    setDeal17:'补仓回调',
    setDeal18:'确定',
    setDeal19:'首单额度不能低于5',
    setDeal20:'倍投比例不能低于1',
    setDeal21:'日夜模式',
    setDeal22:'第1手',
    setDeal23:'盈利回调',
    setDeal24:'倍投比例',
    setDeal25:'倍',
    setDeal26:'添加下一手',
    setDeal27:'首单额度',
    setDeal28:'倍投上限',
    setDeal28_1:'第',
    setDeal28_2:'手',
    setDeal28_3:'单',
    /* transaction.tsx */
    transaction1:'提示',
    transaction2:'取消',
    transaction3:'确认置换',
    transaction4:'确定将全部',
    transaction5:'置换成FIL',
    transaction6:'置换的FIL可参加“租赁宝”享受高额收益哦~',
    transaction7:'(为避免影响FIL正常置换，请先“暂停”所有执行中策略！置换完成后可重新启动~)',
    transaction8:'可用数量',
    transaction9:'置换FIL数量(FIL)',
    transaction10:'如何参与租赁宝？',
    transaction11:'减仓金额最少为15U！',
    transaction12:'可用持仓金额不能少于15U！',
    transaction13:'减仓成功！',
    transaction14:'可用持仓金额：',
    transaction15:'减仓金额',
    transaction16:'减仓数量',
    transaction17:'确认减仓',
    transaction18:'拆单金额不能大于持仓金额！',
    transaction19:'提醒',
    transaction20:'是否确定拆单',
    transaction21:'取消',
    transaction22:'确认',
    transaction23:'拆单成功！',
    transaction24:'策略拆单',
    transaction25:'可用持仓金额：',
    transaction26:'拆单金额',
    transaction27:'请输入想要拆到新订单的持仓金额',
    transaction28:'减仓数量',
    transaction29:'提示：策略拆分仅做持仓金额和数量的拆分，拆分后的策略参数 将默认拆单金额为第一手投入金额，持仓均价参数不变。',
    transaction30:'确认拆单',
    transaction31:'补仓金额必须为大于等于5的数字',
    transaction32:'提交成功！',
    transaction33:'手动补仓',
    transaction34:'补仓金额',
    transaction35:'请输入补仓金额',
    transaction36:'当前均价：',
    transaction37:'当前卖一价：',
    transaction38:'补仓后均价：',
    transaction39:'补仓后涨幅：',
    transaction40:'补仓后止盈价：',

    transaction41:'补仓',
    transaction42:'当前手数',
    transaction43:'增加手数',
    transaction44:'请输入增加的手数',
    transaction45:'下一手额度',
    transaction46:'首单额度',
    transaction47:'倍投手数',
    transaction48:'第1手',
    transaction49:'止盈比例',
    transaction50:'盈利回调',

    transaction51:'倍投比例',
    transaction52:'倍',
    transaction53:'倍投手数',
    transaction54:'第',
    transaction55:'手',
    transaction56:'补仓跌幅',
    transaction57:'补仓回调',


    transaction58:'是否停止该策略？',
    transaction59:'系统将会暂停您的策略',
    transaction60:'停止成功',
    transaction61:'是否继续策略？',
    transaction62:'系统将继续您的策略运行',
    transaction63:'已恢复运行',
    transaction64:'提示',
    transaction65:'是否开始该策略',
    transaction66:'策略开始',
    transaction67:'置换成功',
    transaction68:'单次策略',
    transaction69:'循环策略',
    transaction70:'切换至循环策略',
    transaction71:'切换至单次策略',
    transaction72:'持仓金额',
    transaction73:'持仓均价',
    transaction74:'当前手数',
    transaction75:'持仓数量',
    transaction76:'当前价格',
    transaction77:'涨幅',
    transaction78:'止盈价格',
    transaction79:'下手补仓价',
    transaction80:'浮亏',
    transaction81:'策略详情',
    transaction82:'订单启动中无法修改，请先停止策略',
    transaction83:'风险提示',
    transaction84:'修改策略会影响既定策略执行逻辑，请慎重选择！',
    transaction85:'确认修改',
    transaction86:'修改策略',
    transaction87:'添加策略',
    transaction88:'点击展开',
    transaction89:'点击收起',
    transaction90:'此策略为策略广场策略，通过系统统计海量因子指标，建模历史数据回测，经大数据分析提供建议指标，并由策略分析专家进行经验性调整，配置为最优质参数！',
    transaction91:'为防止您误操作导致参数变更，影响您的收益！因此该策略暂不支持查看和修改！',
    transaction92:'操作小贴士',
    transaction93:'策略广场专家定制策略可将短期行情利益最大化，同时可应对夜间跳水等极端行情！您只需保证持仓金额充足即可！',
    transaction94:'启动策略后遵循AI机器人操作，减少手动操作及人工干预！',
    transaction95:'策略选择',
    transaction96:'应用成功！',
    transaction97:'一键应用',
    transaction98:'启动策略',
    transaction99:'停止策略',
    transaction100:'继续策略',
    transaction101:'订单停止中无法手动补仓，请先启动策略',
    transaction102:'手动补仓',
    transaction103:'请先停止策略',
    transaction104:'订单转移',
    transaction105:'置换成FIL',
    transaction106:'手动减仓',
    transaction107:'策略拆单',
    transaction108:'手动减仓',
    transaction109:'更多操作',


    transaction110:'单次',
    transaction111:'循环',
    transaction112:'数量',
    transaction113:'浮亏',



    transaction114:'全选',
    transaction115:'请先选择策略',
    transaction116:'修改策略',
    transaction117:'停止策略',
    transaction118:'切换至单次',
    transaction119:'切换至循环',
    transaction120:'提示',
    transaction121:'是否清仓所选交易对？',
    transaction122:'清仓卖出',



    transaction123:'循环',
    transaction124:'单次',
    transaction125:'搜索币种名称',
    transaction126:'搜索',
    transaction127:'火币账户资产',
    transaction128:'批量设置',
    transaction129:'全部',








  };
}
