import { Modal } from 'antd-mobile'
import React from 'react'
import styles from './changeToFil.module.scss'
import { getLanguage } from '../../../../locate'

interface MyProps{
  onCancel:()=>void
  onConfirm:()=>void
  toProblem:()=>void
  replaceInfo:{
    originSymbol:string,
    originNum:string,
    targetSymbol:string,
    targetNum:string
  }
}
function ChangeToFil(props:MyProps) {
  const l = getLanguage().All
  const {onCancel,onConfirm,replaceInfo,toProblem} = props
  return (
    <Modal
      className={styles.web}
      wrapClassName={'web'}
      visible={true}
      maskClosable={false}
      title={<div style={{fontSize:'18px',color:"#CFD2E7",padding:"23px 0"}}>{l.transaction1}</div>}
      transparent
      footer={[{ text:l.transaction2, onPress: () => { onCancel()} },{ text:l.transaction3, onPress: () => { onConfirm()} }]}
      onClose={()=>{}}
    >
      <div className={styles.desc}>
        <div>{l.transaction4}{ replaceInfo.originSymbol }{l.transaction5}？{l.transaction6}</div>
        <div className={styles.des}>{l.transaction7}</div>
        <p className={styles.p1}>{l.transaction8}({replaceInfo.originSymbol})：{replaceInfo.originNum}</p>
        <p className={styles.p2}>{l.transaction9}：≈ {replaceInfo.targetNum}</p>
        <div className={styles.question} onClick={()=>toProblem()}>{l.transaction10}</div>
      </div>
    </Modal>
  )
}

export default ChangeToFil
