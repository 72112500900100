import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getUserAssets } from '../../../api/lease'
import { useQuery } from '../../../utils/custom_hooks'
import { ToastFail } from '../../../utils/http/err_handle'
import { throttle, toKeepDecimal } from '../../../utils/tools'
import { AmountVerificationfor2 } from '../../../utils/verification/form-verification'
import ProgressBar from '../components/progressBar'
import styles from "./productInfo.module.scss"
import { Modal, Toast } from 'antd-mobile'
import { miningBuy } from '../../../api/lease'
import encrypt from '../../../utils/encrypt'
import { getLanguage } from '../../../locate'

interface Info {
  name: string,
  key: string,
  blue: boolean
}

function ProductInfo() {
  const l = getLanguage().All
  useEffect(() => {
    document.title = l.productInfo1
  }, [])
  let {
    id,
    name,
    expectRate,
    period,
    balanceDay,
    buyThreshold,
    amount,
    spareAmount,
    profitStartTime,
    applyStartTime,
    applyEndTime,
    state
  } = useQuery()
  profitStartTime = moment(Number(profitStartTime)).format('YYYY-MM-DD HH:mm:ss')
  applyStartTime = moment(Number(applyStartTime)).format('YYYY-MM-DD HH:mm:ss')
  applyEndTime = moment(Number(applyEndTime)).format('YYYY-MM-DD HH:mm:ss')
  const [ visible, setVisible ] = useState<boolean>(false)
  const [ pwdVisible, setPwdVisible ] = useState<boolean>(false)
  const [ fil, setFil ] = useState<any>({})
  const [ buyAmount, setBuyAmount ] = useState<string>('')
  const history = useHistory()
  const [ check, setCheck ] = useState<boolean>(false)
  const [ pwd, setPwd ] = useState('')
  useEffect(() => {
    getUserAssets("3").then(res => {
      setFil(res.data)
    }).catch(ToastFail)
  }, [])
  const infos: Info[] = [
    { name: l.productInfo2, key: `${expectRate}%`, blue: true },
    { name: l.productInfo3, key: period, blue: false },
    { name: l.productInfo4, key: balanceDay, blue: false },
    { name: l.productInfo5, key: buyThreshold, blue: false },
    { name: l.productInfo6, key: applyStartTime, blue: false },
    { name: l.productInfo7, key: applyEndTime, blue: false },
    { name: l.productInfo8, key: profitStartTime, blue: false },
  ]
  const onSubmit = () => {
    if (!buyAmount) {
      return Toast.fail(l.productInfo9)
    }
    if (!check) {
      return Toast.fail(l.productInfo10)
    }
    setPwdVisible(true)
  }
  const getState = (state: number | string): string => {
    state = typeof state === 'string' ? Number(state) : state
    return [ l.productInfo11, l.productInfo12, l.productInfo13, l.productInfo14, l.productInfo15,l.productInfo16 ][state] ?? ''
  }
  const setAll = (): void => {
    let min = Math.min(...[ spareAmount, fil.filBalance ])
    setBuyAmount(min.toFixed(2))
  }
  //判断是否显示购买模块
  const showBuyModule = (): boolean => {
    return state==='1'
  }
  return (
    <div className={styles.productInfo}>
      <div className={styles.infoMain}>
        <div className={styles.productsName}>
          <img src={require('../imgs/filecoin-logo@2x.png').default} alt=""/>
          {decodeURI(name)}
          <span
            className={[ styles.productsStaus, state === '2' ? styles.productsRed : '' ].join(" ")}>{getState(state)}</span>
        </div>
        <ProgressBar amount={Number(amount)} spareAmount={Number(spareAmount)}/>
        <div>
          {infos.map((info, i) => (
            <div className={styles.infoItem} key={i}>
              {info.name}
              <span className={info.blue ? styles.blue : ''}>{info.key}</span>
            </div>
          ))}
        </div>
        {showBuyModule() ? <>
          <div className={styles.buyTitle}>{l.productInfo17}</div>
          <div className={styles.numInput}>
            <input type="number" placeholder={`${l.productInfo18}${spareAmount}`} value={buyAmount} onChange={(e) => {
              let { value } = e.currentTarget
              value = value === '' ? value : AmountVerificationfor2(value) || buyAmount
              setBuyAmount(value)
            }}/>
            <span onClick={setAll}>{l.productInfo19}</span>
          </div>
          <div className={styles.balance}>{l.productInfo20} <span>{toKeepDecimal(fil.filBalance || 0, 4)} FIL</span></div>
        </> : ''}
        <div className={styles.tipsTitle}>{l.productInfo21}</div>
        <div className={styles.tips}>
          1、{l.productInfo22}<br/>
          2、{l.productInfo23}<br/>
          3、{l.productInfo24}<br/>
          4、{l.productInfo25}<br/> 5、{l.productInfo26} {buyThreshold} FIL。
        </div>
        {
          showBuyModule() ? <>
            <div className={styles.agreement} onClick={() => {
              setCheck(!check)
            }
            }>
              <i className={[ 'icon', check ? 'i-ziyuan' : 'i-ziyuan1' ].join(' ')}/>
              {l.productInfo26_1}
              <span onClick={() => {
                history.push('/lease/agreement')
              }}> {l.productInfo27}  </span>
            </div>
            <div className={styles.btn} onClick={throttle(onSubmit)}>{l.productInfo28}</div>
          </> : ''
        }
      </div>
      <Modal
        visible={visible}
        transparent
        footer={[ {
          text: l.productInfo31, onPress: () => {
            console.log('ok')
            history.replace('/lease/myProduct')
          }
        } ]}
        className="myModal"
      >
        <div className={styles.modal}>
          <div className={styles.modalTitle}>{l.productInfo29}</div>
          <div className={styles.modalTips}>{l.productInfo30}<br/>
            {l.productInfo32}（UTC+8）<br/>{profitStartTime}{l.productInfo33}
          </div>
        </div>
      </Modal>
      <Modal
        visible={pwdVisible}
        transparent
        onClose={() => {
          setPwdVisible(false)
        }}
        footer={[ {
          text: l.productInfo31, onPress: () => {
            miningBuy({
              buyAmount: Number(buyAmount),
              productId: Number(id),
              safePwd: encrypt.encrypt(pwd)
            }).then(res => {
              setVisible(true)
            }).catch(ToastFail)
          }
        } ]}
        className="myModal"
      >
        <div className={styles.modal}>
          <div className={styles.modalTitle}>{l.productInfo34_1}</div>
          <input type="password" value={pwd} placeholder={l.productInfo34} onChange={(e) => {
            let { value } = e.currentTarget
            setPwd(value)
          }}/>
        </div>
      </Modal>
    </div>
  )
}

export default ProductInfo
