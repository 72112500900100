import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ToastFail } from '../../../utils/http/err_handle'
import styles from './orderInfo.module.scss'
import { getOrderInfo } from "../../../api/lease"
import moment from 'moment'
import { getLanguage } from '../../../locate'

interface Info {
  name: string,
  key: string | number,
  blue: boolean
}

interface InfoObj {
  expectRate: number,
  surplusNumDay: number,
  countEarnings: number,
  spareMoney: number,
  balanceDay: number,
  buyThreshold: number,
  closeEarningsList: any[],
  [key: string]: any
}

function OrderInfo() {
  const l = getLanguage().All
  const { id } = useParams<{ id: string }>()
  const [ data, setData ] = useState<InfoObj>({
    expectRate:0,
    surplusNumDay:0,
    countEarnings:0,
    spareMoney:0,
    balanceDay:0,
    buyThreshold:0,
    closeEarningsList:[]
  })
  useEffect(() => {
    document.title = l.orderInfo1
    getOrderInfo(id).then(res => {
      let {applyStartTime,applyEndTime,profitStartTime,nextAccrualTime}=res.data
      res.data.applyStartTime=moment(applyStartTime).format('YYYY-MM-DD HH:mm:ss')
      res.data.applyEndTime=moment(applyEndTime).format('YYYY-MM-DD HH:mm:ss')
      res.data.profitStartTime=moment(profitStartTime).format('YYYY-MM-DD HH:mm:ss')
      res.data.nextAccrualTime=moment(nextAccrualTime).format('YYYY-MM-DD HH:mm:ss')
      setData(res.data)
    }).catch(ToastFail)
  }, [])
  const infos: Info[] = [
    { name: l.orderInfo2, key: `${data.expectRate}%`, blue: true },
    { name: l.orderInfo3, key: data.surplusNumDay, blue: false },
    { name: l.orderInfo4, key: data.countEarnings, blue: true },
    { name: l.orderInfo5, key: data.spareMoney, blue: false },
    { name: l.orderInfo6, key: data.balanceDay, blue: false },
    { name: l.orderInfo7, key: data.applyStartTime, blue: false },
    { name: l.orderInfo8, key: data.applyEndTime, blue: false },
    { name: l.orderInfo9, key: data.profitStartTime, blue: false },
    { name: l.orderInfo10, key: data.nextAccrualTime, blue: false },
  ]
  return (
    <div className={styles.orderInfo}>
      <div className={styles.infoMain}>
        <div className={styles.productsName}>
          <img src={require('../imgs/filecoin-logo@2x.png').default} alt=""/>
          {data.productName}
          <span className={styles.time}>{moment(data.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
        </div>
        <div className={styles.productsQuota}>
          {data.money}
          <span>{l.orderInfo11}</span>
        </div>
        {infos.map((info, i) => (
          <div className={styles.infoItem} key={i}>
            {info.name}
            <span className={info.blue ? styles.blue : ''}>{info.key}</span>
          </div>
        ))}
        <div className={styles.tipsTitle}>{l.orderInfo12}</div>
        <div className={styles.tips}>
          1、{l.orderInfo13}<br/>
          2、{l.orderInfo14}<br/>
          3、{l.orderInfo15}<br/>
          4、{l.orderInfo16}<br/> 5、{l.orderInfo17} {data.buyThreshold} FIL。
        </div>
      </div>
      <div className={styles.title}>{l.orderInfo18}</div>
      <div className={styles.logs}>
        {data.closeEarningsList.map((e: any, i: number) => (
          <div className={styles.logsItem} key={i}>
            <span className={styles.itemName}>{e.typeName}</span>
            <span className={styles.itemTime}>{moment(e.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
            <span className={styles.itemNum}>+{e.amount}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OrderInfo
