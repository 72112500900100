import { Toast, WhiteSpace } from 'antd-mobile'
import { action, observable } from 'mobx'
import { useLocalObservable, Observer } from 'mobx-react-lite'
import React, { FormEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { addSimpleStrategy, updateSimpleStrategy } from '../../api/strategy'
import { LOCAL_STRATEGY_ID } from '../../lib/global-key'
import { useQuery } from '../../utils/custom_hooks'
import { ToastFail } from '../../utils/http/err_handle'
import { ObjectToString } from '../../utils/tools'
import { AmountVerification, UintVerification } from '../../utils/verification/form-verification'
import choose from './imgs/choose.png'
import nochoose from './imgs/nochoose.png'
import styles from './tab1-deal.module.css'
import { getLanguage } from '../../locate'

interface Tab1Props {
  state?: any
}

const InitialData = {
  name: '',
  num: '',
  firstAmount: '',
  stopPi: '',
  stopReturnPi: '',
  addDownPi: '',
  addDownReturnPi: '',
  type: 1,
  strategyId: ''
}

type dataKey = keyof typeof InitialData

function Tab1(props: Tab1Props) {
  const l = getLanguage().All
  const { state } = props
  const { symbol } = useQuery()
  const id = localStorage.getItem(LOCAL_STRATEGY_ID)
  const history = useHistory()
  const ff = useLocalObservable(() => ({
    data: {
      ...(state || InitialData),
      symbol,
    },
    setData(data: typeof InitialData) {
      this.data = data
    },
    handleChangeAmount(key: dataKey) {
      return (event: FormEvent<HTMLInputElement>) => {
        this.setData({
          ...this.data,
          [key]: event.currentTarget.value === '' ? ''
            : (AmountVerification(event.currentTarget.value) || this.data[key]),
        })
      }
    },
    handleChange(key: dataKey) {
      return (event: FormEvent<HTMLInputElement>) => {
        this.setData({
          ...this.data,
          [key]: event.currentTarget.value
        })
      }
    },
    handleChangeUint(key: dataKey) {
      return (event: FormEvent<HTMLInputElement>) => {
        this.setData({
          ...this.data,
          [key]: event.currentTarget.value === '' ? ''
            : (UintVerification(event.currentTarget.value) || this.data[key])
        })
      }
    },
    setType(type: number) {
      this.data.type = type
    }
  }), {
    data: observable,
    setData: action,
  })
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = { ...ObjectToString(ff.data), StrategyId: Number(id) };
    (id === '0' ? addSimpleStrategy(data) : updateSimpleStrategy(data))
      .then(({ data }) => {
        Toast.info(id === '0' ? l.setDeal5: l.setDeal6)
        const rid = (data && data.id) || id
        localStorage.setItem(LOCAL_STRATEGY_ID, rid)
        history.goBack()
      })
      .catch(ToastFail)
  }
  return (
    <Observer>
      {() => {
        const data = ff.data
        return (
          <div className={styles.tabContainer}>
            <form onSubmit={handleSubmit}>
              <div className={styles.form_content}>
                <div className={styles.item}>
                  <div className={styles.title}>{l.setDeal7}</div>
                  <div className={styles.inp}>
                    <input type="text" placeholder={l.setDeal8} value={data.name} onInput={ff.handleChange('name')} />
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>{l.setDeal8_1}</div>
                  <div className={styles.selected}>
                    <div onClick={() => ff.setType(0)} className={styles.chose1}>
                      <img srcSet={data.type === 0 ? choose : nochoose} width="15px" height='15px' alt=""/>
                      <span>{l.setDeal9}</span>
                    </div>
                    <div onClick={() => ff.setType(1)} className={styles.chose2}>
                      <img srcSet={data.type === 1 ? choose : nochoose} width="15px" height='15px' alt=""/>
                      <span>{l.setDeal10}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>{l.setDeal11} <span>(USDT)</span></div>
                  <div className={styles.inp}>
                    <input required step="any" type="number" pattern="number" placeholder='0.0000' value={data.firstAmount} onInput={ff.handleChangeAmount('firstAmount')} />
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>{l.setDeal12}</div>
                  <div className={styles.inp}>
                    <input required step="any" type="number" pattern="number" placeholder='0' value={data.num} onInput={ff.handleChangeUint('num')}/>
                  </div>
                </div>
                <div className={styles.desc}>{l.setDeal13}</div>
                <div className={styles.item}>
                  <div className={styles.title}>{l.setDeal14}<span>(%)</span></div>
                  <div className={styles.inp}>
                    <input required step="any" type="number" pattern="number" placeholder='0.0' value={data.stopPi} onInput={ff.handleChangeAmount('stopPi')}/>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>{l.setDeal15}<span>(%)</span></div>
                  <div className={styles.inp}>
                    <input required step="any" type="number" pattern="number" placeholder='0.0' value={data.stopReturnPi} onInput={ff.handleChangeAmount('stopReturnPi')} />
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>{l.setDeal16}<span>(%)</span></div>
                  <div className={styles.inp}>
                    <input required step="any" type="number" pattern="number" placeholder='0.0' value={data.addDownPi} onInput={ff.handleChangeAmount('addDownPi')} />
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.title}>{l.setDeal17}<span>(%)</span></div>
                  <div className={styles.inp}>
                    <input required step="any" type="number" pattern="number" placeholder='0.0' value={data.addDownReturnPi} onInput={ff.handleChangeAmount('addDownReturnPi')} />
                  </div>
                </div>
                <WhiteSpace size="md" />
              </div>
              <div className={styles.btn1}>
                <button className={styles.confirm_btn}>{l.setDeal18}</button>
              </div>
            </form>
          </div>
        )
      }}
    </Observer>
  )
}

export default Tab1
