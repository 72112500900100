export function NumberVerification(text: string) {
  return /^-?\d*\.?\d*$/.test(text) ? text : undefined
}

export function PhoneAndEmailVerification(text: string) {
  return /(^0?(13[0-9]|15[0-9]|153|156|18[0-9]|17[0-9])[0-9]{8}$)|(^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$)/i.test(
    text,
  )
    ? text
    : undefined
}

export function AmountVerification(text: string) {
  return /^([1-9]\d{0,9}(\.\d{0,5})?|0|0\.\d{0,5})$/.test(text) ? text : undefined
}
//限制两位销售
export function AmountVerificationfor2(text: string) {
  return /^([1-9]\d{0,9}(\.\d{0,2})?|0|0\.\d{0,2})$/.test(text) ? text : undefined
}

export function UintVerification(text: string) {
  return /^[1-9]\d{0,5}$/.test(text) ? text : undefined
}
export function English(text : string){
  return !/[\u4e00-\u9fa5]/.test(text) ? text : undefined
}
