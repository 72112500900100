import zh_CN from './zh_CN'
import en_US from './en_US.json'
// import ja_JP from './ja_JP.json'
// import ko_KR from './ko_KR.json'

export default {
  zh_CN,
  en_US,
  // ja_JP,
  // ko_KR,
}
