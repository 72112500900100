import React from 'react'
import styles from './null-data.module.scss'
import { getLanguage } from '../../../../locate'

function NullData() {
  const l = getLanguage().All
  return (
    <div className={styles.main}>
      <img src={require('../../imgs/nodata.png').default} alt="" className={styles.img}/>
      <div className={styles.desc}>{l.noData}</div>
    </div>
  )
}

export default NullData
