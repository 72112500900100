import { fromPairs } from 'ramda'
import { KeyValuePair } from 'ramda/tools'

export function panNum(num: number) {
  return num > 0 ? '+' + num : num + ''
}

/**
 * 选择保留几位小数
 * @param num 要保留小数的数
 * @param digits  保留几位
 */
export function toKeepDecimal(num: number | string, digits: number): string | undefined {
  const reg = new RegExp(`^[-\\d]+(?:\\.\\d{0,${digits}})?`)
  const matchResult = num.toString().match(reg)?.[0]
  let result
  if (matchResult) {
    const segments = matchResult.split('.')
    const tail = segments[1] || []
    result = segments[0] + '.' + Array.from(Array(digits), (v, i) => tail[i] ?? 0).join('')
  }
  return result
}

export function ObjectToString(obj: {[key: string]: any}) {
  function convert_to_string(o: typeof obj) {
    const newObj: typeof obj = Array.isArray(o) ? [] : {}
    for (let key in o) {
      if (typeof o[key] === 'number') {
        newObj[key] = String(o[key])
      } else if (typeof o[key] === 'object') {
        newObj[key] = convert_to_string(o[key])
      } else {
        newObj[key] = o[key]
      }
    }
    return newObj
  }

  return convert_to_string(obj)
}
export function urlQuery() {
  // return new URLSearchParams(location.search)
  const matchs = window.location.search.match(/\?(.+)/)
  if (!matchs) return {}
  const query = matchs[1]
  const entries: Array<KeyValuePair<string, string>> = []
  query.split('&').forEach(v => {
    entries.push(v.split('=') as KeyValuePair<string, string>)
  })
  return fromPairs(entries)
}
/**
 * 函数节流
 * @param fn
 * @param wait
 */
export function throttle<T = (...args: any) => void>(fn: T, wait: number = 2000): T {
  let _lastTime: number | null = null

  return (function (...args: any) {
    let _nowTime = +new Date() // 前面加一个加号代表调用对象中的valueOf方法
    if (!_lastTime || _nowTime - _lastTime > wait) {
      ;(fn as any)(...args)
      _lastTime = _nowTime
    }
  } as unknown) as T
}

/**
 * 函数防抖
 * @param fn
 * @param wait
 */
export function debounce<T, R = void>(fn: (args: T) => R, wait: number = 2000): (args: T) => void {
  let timer: any
  return (function (...args) {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    timer = setTimeout(function () {
      ;(fn)(...args)
    }, wait)
  })
}
