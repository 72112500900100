import { Toast } from 'antd-mobile'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { clone } from 'ramda'
import React, { FormEvent, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { addNewFilAddress } from '../../../api/lease'
import { ToastFail } from '../../../utils/http/err_handle'
import { AmountVerification, English, UintVerification } from '../../../utils/verification/form-verification'
import TextField from '../fil-tb/components/TextField'
import styles from './insert.module.scss'
import { getLanguage } from '../../../locate'

function Insert() {
  const l = getLanguage().All
  const initialFormState = {
    address: '',
    remark: ''
  }
  const data = useLocalObservable(() => ({
    value: clone(initialFormState),
    setValue(key: keyof typeof initialFormState, val: string) {
      this.value[key] = val
    },
    clearValue() {
      this.value = clone(initialFormState)
    }
  }), { setValue: action, clearValue: action })
  const form = data.value
  useEffect(() => {
    document.title = l.address11
  }, [])
  const handleChange = (key: keyof typeof initialFormState, verification: Function = (text: string) => text) => (event: FormEvent<HTMLInputElement> | string) => {
    let value = typeof event === 'string' ? event : event.currentTarget.value
    data.setValue(key, value === '' ? ''
      : (verification(value) || data.value[key]) as any)
  }
  const history =useHistory()
  const addAddress=(form:{address:string,remark:string})=>{
    if(!form.address.trim().length) return Toast.info(l.address12)
    if(!form.remark.trim().length) return Toast.info(l.address13)

    addNewFilAddress(form).then(res=>{
      Toast.success(l.address14)
      history.goBack()
    }).catch(ToastFail)
  }
  return (
    <div className={styles.main}>
      <div className={styles.scroll}>
        <div className={styles.textField}>
          <TextField
            onChange={handleChange('address',English)}
            value={form.address}
            label={l.address3}
            placeholder={l.address4}
          />
        </div>
        <div className={styles.textField}>
          <TextField
            onChange={handleChange('remark')}
            value={form.remark}
            label={l.address5}
            placeholder={l.address6}
          />
        </div>
      </div>
      <div className={styles.btn}>
        <button onClick={()=>addAddress(form)}>{l.address7}</button>
      </div>
    </div>
  )
}

export default observer(Insert)
