import React, { CSSProperties, PureComponent } from 'react'
import ScrollBar from '@better-scroll/scroll-bar'
import BScroll from '@better-scroll/core'
import PullUp from '@better-scroll/pull-up'
import PullDown from '@better-scroll/pull-down'
import { BounceConfig } from '@better-scroll/core/dist/types/Options'
import styles from './pull-load-list.module.css'
import { getLanguage } from '../../locate'

BScroll.use(PullDown);
BScroll.use(PullUp);
BScroll.use(ScrollBar);

export type PageNum = {
  pageSize: number,
  pageNo: number,
}

export interface PullLoadRef {
  refresh: () => void
}

interface PullLoadListProps {
  children: React.ReactNode
  style?: CSSProperties
  onReq?: (pageNum: PageNum) => Promise<any[]>
  pageSize?: number
  pullUpLoad?: boolean
  onScroll?: (data: {x: number, y: number}) => void
  bounce?: boolean | Partial<BounceConfig>
  bounceTime?: number
  empty?: React.ReactNode
  click?: boolean
}

const TIME_BOUNCE = 500
const STOP = 56

const InitialState = {
  beforePullDown: true,
  isPullingDown: false,
  isOkPullRefresh: false,
  isPullUpLoad: false,
  upLoadFinish: false,
}

class PullLoadList extends PureComponent<PullLoadListProps, typeof InitialState> {

  state = InitialState

  private pageNum = {
    pageNo: 0,
    pageSize: 20,
  }

  private wrapperRef: HTMLDivElement | null | undefined

  private bs: BScroll<{pullDownRefresh: true, pullUpLoad: true}> | undefined

  componentDidMount() {
    if (!this.wrapperRef) return
    const { pullUpLoad = true, bounce = true, bounceTime = TIME_BOUNCE, pageSize = 20, click = true } = this.props
    this.pageNum.pageSize = pageSize
    this.bs = new BScroll(this.wrapperRef, {
      // probeType: 2,
      pullUpLoad: pullUpLoad ? true : undefined,
      scrollbar: true,
      pullDownRefresh: {
        stop: STOP,
      },
      bounce,
      bounceTime,
      swipeBounceTime: 200,
      click: click,
      // preventDefaultException: {
      //   className: /(^|\s)currency-item(\s|$)/
      // }
    });
    this.bs.on('pullingDown', this.pullingDownHandler)
    if(pullUpLoad) this.bs.on('pullingUp', this.pullingUpHandler)
    this.bs.on('scroll', this.scrollHandler)
    this.bs.autoPullDownRefresh()
  }

  componentDidUpdate(prevProps: Readonly<PullLoadListProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.children !== this.props.children) {
      this.bs?.refresh()
    }
  }

  render() {
    const l = getLanguage().All
    const {pageNo} = this.pageNum
    const {children, pullUpLoad = true, style, empty} = this.props
    const {beforePullDown, isOkPullRefresh, isPullingDown, upLoadFinish, isPullUpLoad} = this.state
    return (
      <div ref={ref => this.wrapperRef = ref} style={{ height: '100%', overflow: 'hidden', position: 'relative', ...style }}>
        <div style={{ minHeight: 'calc(100% + 1px)' }}> {/* 必须要超过容器一像素，否者无法下拉刷新 */}
          <div className={styles.pulldown_wrapper}>
            {beforePullDown && (
              <div>
                <span>{isOkPullRefresh ? l.free_to_refresh : l.pull_to_refresh}</span>
              </div>
            )}
            {!beforePullDown && (
              <div>
                {isPullingDown && (
                  <div>
                    <span>{l.loading}</span>
                  </div>
                )}
                {!isPullingDown && (
                  <div>
                    <span>{l.loadComplete}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          {children}
          {!isPullingDown && empty}
          {!empty && pullUpLoad && pageNo !== 0 && (
            <div className={styles.pullup_tips}>
              <div className="before-trigger">
                <span className="pullup-txt">{upLoadFinish ? l.noMoreData : !isPullUpLoad ? l.pull_up_to_load_more : l.loading}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  private scrollHandler = ({ x, y }: {x: number, y: number}) => {
    const {onScroll} = this.props
    onScroll?.({x, y})
    if (y >= 90) {
      this.setState({
        isOkPullRefresh: true,
      })
    } else {
      this.setState({
        isOkPullRefresh: false,
      })
    }
  }

  private finishPullDown = () => {
    const bs = this.bs
    if (!bs) return
    bs.finishPullDown();
    this.setState({
      isPullingDown: false,
    })
    this.pageNum.pageNo = 1;
    setTimeout(() => {
      this.setState({
        beforePullDown: true
      })
      bs.refresh();
    }, TIME_BOUNCE + 100)
  }

  private pullingDownHandler = () => {
    console.log('trigger pullDown')
    this.setState({
      beforePullDown: false,
      isPullingDown: true,
    })
    const {onReq} = this.props
    const {pageSize} = this.pageNum
    onReq?.({pageNo: 1, pageSize})
      .then((v) => {
        if (Array.isArray(v)) {
          this.setState({
            upLoadFinish: v.length < pageSize
          })
        }
        // if (componentIsMounted.current) {
          this.finishPullDown()
        // }
      })
  }

  private pullingUpHandler = () => {
    const {onReq} = this.props
    const {pageNo, pageSize} = this.pageNum
    this.setState({isPullUpLoad: true})
    onReq?.({ pageNo: pageNo + 1, pageSize })
      .then((v) => {
        if (!Array.isArray(v)) return Promise.reject(new Error())
        this.pageNum.pageNo += 1
        this.setState((data) => ({
          ...data,
          isPullUpLoad: false,
          upLoadFinish: v.length < pageSize
        }))
        this.bs?.finishPullUp();
        if (v.length < pageSize) {
          this.bs?.closePullUp();
        }
        this.bs?.refresh();
      })
      .catch(() => {
        this.setState({
          isPullUpLoad: false
        })
        this.bs?.finishPullUp();
      })
  }

  refresh() {
    this.bs?.autoPullDownRefresh()
  }

  scrollRefresh() {
    this.bs?.refresh()
  }
}

export default PullLoadList
