import { Toast, WhiteSpace } from 'antd-mobile'
import { useLocalObservable, Observer } from 'mobx-react-lite'
import React, { FormEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { addfzStrategy, updateStrategy } from '../../api/strategy'
import { LOCAL_STRATEGY_ID } from '../../lib/global-key'
import { useQuery } from '../../utils/custom_hooks'
import { ToastFail } from '../../utils/http/err_handle'
import { ObjectToString } from '../../utils/tools'
import { AmountVerification } from '../../utils/verification/form-verification'
// import edit from './imgs/bianji.png'
import choose from './imgs/choose.png'
import del from './imgs/delete.png'
import nochoose from './imgs/nochoose.png'
import styles from './tab2-deal.module.css'
import { getLanguage } from '../../locate'

const InitialParam = {
  bt_pi: '1',
  down_pi: '',
  down_return_pi: '',
}

const InitialState = {
  name: '',
  type: 1, // 0 日间, 1 夜间
  symbol: '',
  first_amount: '',
  stop_pi: '',
  stop_return_pi: '',
  params: [
    InitialParam,
  ],
}

interface Tab2Props {
  state?: any
}

function Tab2(props: Tab2Props) {
  const l = getLanguage().All
  const { symbol } = useQuery()
  const id = localStorage.getItem(LOCAL_STRATEGY_ID)
  const state = props.state as typeof InitialState || InitialState
  const history = useHistory()
  const data = useLocalObservable(() => ({
    ...state,
    symbol,
    remove_param(index: number) {
      this.params.splice(index, 1)
    }
  }))
  // const [data, setData] = useState()
  const handleChange = (key: keyof typeof data) => (event: FormEvent<HTMLInputElement>) => {
    (data[key] as unknown) = event.currentTarget.value === '' ? ''
      : (AmountVerification(event.currentTarget.value) || data[key]) as any;
  }
  const handleChangeIndex = (key: keyof typeof data.params[0], index: number) => (event: FormEvent<HTMLInputElement>) => {
    data.params[index][key] = event.currentTarget.value === '' ? ''
      : AmountVerification(event.currentTarget.value) || data.params[index][key];
  }
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (Number(data.first_amount) < 5) {
      return Toast.fail(l.setDeal19)
    }
    for (let v of data.params) {
      if (Number(v.bt_pi) < 1) {
        return Toast.fail(l.setDeal20)
      }
    }
    const fdata = { ...ObjectToString(data), StrategyId: Number(id) };
    (id === '0' ? addfzStrategy(fdata) : updateStrategy(fdata))
      .then(({ data }) => {
        Toast.info(id === '0' ? l.setDeal5 : l.setDeal6)
        const rid = (data && data.id) || id
        localStorage.setItem(LOCAL_STRATEGY_ID, rid)
        history.goBack()
    })
      .catch(ToastFail)
  }
  return (
    <Observer>
      {() => {
        let amount = Number(data.first_amount)
        return (
          <div className={styles.tabContainer}>
            <form onSubmit={handleSubmit}>
              <div className={styles.item}>
                <div className={styles.title}>{l.setDeal7}</div>
                <div className={styles.inp}>
                  <input type="text" placeholder={l.setDeal8} value={data.name} onChange={event => {data.name = event.currentTarget.value}} />
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>{l.setDeal8_1}</div>
                <div className={styles.selected}>
                  <div onClick={() => data.type = 0} className={styles.chose1}>
                    <img srcSet={data.type === 0 ? choose : nochoose} width="15px" height='15px' alt=""/>
                    <span>{l.setDeal9}</span>
                  </div>
                  <div onClick={() => data.type = 1} className={styles.chose2}>
                    <img srcSet={data.type === 1 ? choose : nochoose} width="15px" height='15px' alt=""/>
                    <span>{l.setDeal10}</span>
                  </div>
                </div>
              </div>
              <div style={{height:"9px" ,background:'#F5F5FB'}} />
              <div className={styles.box}>
                <div className={styles.boxhead}>
                  <div className={styles.boxhead_name}>第1手</div>
                  {/*<img srcSet={edit} className={styles.edit} alt=""/>*/}
                  {/*<img srcSet={del} alt=""/>*/}
                </div>
                <div style={{border:'1px solid #2C355C',width:'15px',marginTop:'7px'}} />
                <div className={styles.flexblock}>
                  <div className={styles.col}>
                    <div className={styles.block}>
                      <label>{l.setDeal11}</label>
                      <input
                        required
                        type="number"
                        step="any"
                        pattern="number"
                        value={data.first_amount}
                        onInput={handleChange('first_amount')}
                      />
                      <span className={styles.unit}>
              USDT
            </span>
                    </div>
                  </div>
                  <div className={styles.col} />
                </div>
                <div className={styles.flexblock}>
                  <div className={styles.col}>
                    <div className={styles.block}>
                      <label>{l.setDeal14}</label>
                      <input required step="any" type="number" pattern="number" value={data.stop_pi} onInput={handleChange('stop_pi')} />
                      <span className={styles.unit}>%</span>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.block}>
                      <label>{l.setDeal23}</label>
                      <input required step="any" type="number" pattern="number" value={data.stop_return_pi} onInput={handleChange('stop_return_pi')} />
                      <span className={styles.unit}>%</span>
                    </div>
                  </div>
                </div>
                <div className={styles.flexblock}>
                  <div className={styles.col}>
                    <div className={styles.block}>
                      <label>{l.setDeal16}</label>
                      <input required step="any" type="number" pattern="number" value={data.params[0].down_pi} onInput={handleChangeIndex('down_pi', 0)} />
                      <span className={styles.unit}>%</span>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.block}>
                      <label>{l.setDeal17}</label>
                      <input required step="any" type="number" pattern="number" value={data.params[0].down_return_pi} onInput={handleChangeIndex('down_return_pi', 0)} />
                      <span className={styles.unit}>%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {data.params.slice(1).map((v, index) => (
                  <div className={styles.box} key={index}>
                    <div className={styles.boxhead}>
                      <div className={styles.boxhead_name}>{l.setDeal28_1}{index + 2}{l.setDeal28_2}</div>
                      {/*<img srcSet={edit} className={styles.edit} alt=""/>*/}
                      <img onClick={() => {
                        data.remove_param(index + 1)
                      }} src={del} alt=""/>
                    </div>
                    <div style={{border:'1px solid #2C355C',width:'15px',marginTop:'7px'}} />
                    <div className={styles.col}>
                      <div className={styles.block}>
                        <label>{l.setDeal24}</label>
                        <input required step="any" type="number" pattern="number" value={v.bt_pi} onInput={handleChangeIndex('bt_pi', index + 1)} />
                        <span className={styles.unit}>{l.setDeal25} ≈ {amount *= Number(v.bt_pi)} USDT</span>
                      </div>
                    </div>
                    <div className={styles.flexblock}>
                      <div className={styles.col}>
                        <div className={styles.block}>
                          <label>{l.setDeal16}</label>
                          <input required step="any" type="number" pattern="number" value={v.down_pi} onInput={handleChangeIndex('down_pi', index + 1)} />
                          <span className={styles.unit}>%</span>
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.block}>
                          <label>{l.setDeal17}</label>
                          <input required step="any" type="number" pattern="number" value={v.down_return_pi} onInput={handleChangeIndex('down_return_pi', index + 1)} />
                          <span className={styles.unit}>%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.btns}>
                <button className={styles.left_btn}>
                  {l.setDeal18}
                </button>
                <button
                  className={styles.right_btn}
                  type="button"
                  onClick={() => data.params.push(InitialParam)}
                >
                  {l.setDeal26}
                </button>
              </div>
            </form>
            <WhiteSpace size='md' />
          </div>
        )
      }}
    </Observer>
  )
}

export default Tab2
