import React from 'react'

interface StatusBarProps {
  color?: string
  theme?: 'dark' | 'light'
}

function StatusBar(props: StatusBarProps) {
  const height = localStorage.getItem('status_bar_height') || '22'
  const { color, theme } = props
  const bg_color = color || (theme === 'dark' ? '#044FE0' : '#FFFFFF')
  return (
    <div style={{height: height + 'px', backgroundColor: bg_color}} />
  )
}

export default StatusBar
