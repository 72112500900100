import React, { ReactNode } from 'react'
import styles from './bottom-popup.module.css'

interface BottomPopupProps {
  children: ReactNode
  visible: boolean
  onClose?: () => void
}

function BottomPopup(props: BottomPopupProps) {
  const {visible} = props
  return (
    <div
      className={[styles.container, visible ? styles.show : styles.hide].join(' ')}
    >
      {props.children}
    </div>
  )
}

export default BottomPopup
