import { PageNum } from '../components/pull_load_list/PullLoadList'
import { LoopType } from '../lib/enums'
import reqwest, { runLoading } from '../utils/http/reqwest'
import { ObjectToString } from '../utils/tools'

export function queryStrategyOrder(pageNum: PageNum, type?: LoopType) {
  return reqwest.post('strategy/queryStrategyOrder', {...pageNum, orderType: type})
}

export function stopStrategy(id: string) {
  return reqwest.post('strategy/stopStrategy', {strategyId: id}, runLoading())
}

export function goStrategy(id: string) {
  return reqwest.post('strategy/goStrategy', {strategyId: id}, runLoading())
}

export function startStrategy(symbol: string, id: string) {
  return reqwest.post('strategy/startStrategy', {symbol, strategyId: id}, runLoading())
}

export function queryStrategyDetails(id: number) {
  return reqwest.post('strategy/queryStrategyDetails', {strategyId: id})
}
export function reduceStock(data: { strategyId:number,strategyAmountStr:string,strategyPercent:string }) {
  return reqwest.post('strategy/reduceStock', data,runLoading())
}
export function splitOrder(data: { id:number,amount:string }) {
  return reqwest.post('/strategy/splitOrder', data)
}
export function queryStrategyLibrary(pageNum: PageNum) {
  return reqwest.post('strategy/queryStrategyLibrary', ObjectToString(pageNum))
}

export function addfzStrategy(data: any) {
  return reqwest.post('strategy/addfzStrategy', data, runLoading())
}

export function updateStrategyType(ids: (number | string)[], orderType: LoopType) {
  return reqwest.post('strategy/updateStrategyType', {str: ids.join(','), orderType: String(orderType)}, runLoading())
}

export function addSimpleStrategy(data: any) {
  return reqwest.post('strategy/addSimpleStrategy', data, runLoading())
}

export function updateSimpleStrategy(data: any) {
  return reqwest.post('strategy/updateSimpleStrategy', data, runLoading())
}

export function updateStrategy(data: any) {
  return reqwest.post('strategy/updateStrategy', data, runLoading())
}

export function clearStrategy(ids: (number | string)[]) {
  return reqwest.post('strategy/clearStrategy', {str: ids.join(',')}, runLoading())
}

export function getBalance() {
  return reqwest.post('strategy/getBalance', {})
}

export function lib_apply(id: string, sid: string) {
  return reqwest.post('strategy/oneClickApplication', {strategyId: id, strategyLibId: sid}, runLoading())
}

export function apiManualCover(id: string, num: string, pwd: string) {
  return reqwest.post('strategy/manualCover', {orderId: id, num, safePwd: pwd}, runLoading())
}

export function getReplaceInfo(id: string) {
  return reqwest.post('replacement/replaceInfo', {id: id})
}
export function replaceConfirm(id: string) {
  return reqwest.post('replacement/replaceConfirm', {id: id}, runLoading())
}
export function jumpInfo() {
  return reqwest.get('replacement/jumpInfo')
}
