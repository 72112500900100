import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getOrderPage } from '../../../api/lease'
import Null from '../../../components/null/Null'
import PullLoadList from '../../../components/pull-load-list/PullLoadList'
import { ToastFail } from '../../../utils/http/err_handle'
import styles from './myProduct.module.scss'
import { getLanguage } from '../../../locate'

function MyProduct() {
  const l = getLanguage().All

  useEffect(() => {
    document.title = l.myProduct1
  }, [])
  const [ list, setList ] = useState<any>([])
  const history = useHistory()
  const getState = (state: number): string => {
    return [ l.myProduct2, l.myProduct3, l.myProduct4, l.myProduct5, l.myProduct6, l.myProduct7][state] ?? ''
  }
  return (
    <div className={styles.myProduct}>
      <PullLoadList onReq={pageNum =>
        getOrderPage(pageNum)
          .then(res => {
            const lists = res.data.list
            setList(pageNum.pageNo === 1 ? lists : list.concat(lists))
            return lists
          })
          .catch(ToastFail).then(() => [])} empty={!list.length && (<Null/>)}
      >
        {
          list.map((e: any, i: number) => (
            <div className={styles.productItem} key={i} onClick={() => {
              history.push(`/lease/orderInfo/${e.id}`)
            }}>
              <div className={styles.productsName}>
                <img src={require('../imgs/filecoin-logo@2x.png').default} alt=""/>
                {e.productName}
              </div>
              <div className={styles.productsQuota}>
                {e.money}
                <span>{l.myProduct8}</span>
              </div>
              <div className={styles.productsProfit}>
                <div className={styles.productsPrice}>
                  ≈ {e.expectRate}% <span> <b>{e.surplusNumDay}</b>{l.myProduct9}</span>
                </div>
                <div className={styles.productsTips}>
                  {l.myProduct10} <span>{l.myProduct11}</span>
                </div>
                <div className={styles.productsPrice}>
                  {e.countEarnings} <span> <b>{e.spareMoney.toFixed(2)}</b></span>
                </div>
                <div className={styles.productsTips}>
                  {l.myProduct12} <span>{l.myProduct13}</span>
                </div>
              </div>
            </div>
          ))
        }
      </PullLoadList>
    </div>
  )
}

export default MyProduct
