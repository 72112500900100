import React from 'react'
import { useHistory } from 'react-router-dom'
import StatusBar from '../status-bar/StatusBar'
import styles from './nav-bar.module.css'

interface NavBarProps {
  title: string
  theme?: 'dark' | 'light'
  tran?: number
  fixed?: boolean
}

const cubicEaseOut = function(t: number, b: number, c: number, d: number) {
  return c * ((t = t/d - 1) * t * t + 1) + b;
};

const dark_color = [4, 79, 224]
const light_color = [255, 255, 255]

function NavBar(props: NavBarProps) {
  const { title, tran = 0, fixed = false } = props
  const start = props.theme === 'dark' ? 0 : props.theme === 'light' ? 100 : tran
  const history = useHistory()
  let rgb = [0, 0, 0]
  dark_color.forEach((v, i) => {
    rgb[i] = cubicEaseOut(start, v, light_color[i] - v, 100)
  })
  const color = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
  light_color.forEach((v, i) => {
    rgb[i] = cubicEaseOut(start, v, [44, 53, 92][i] - v, 100)
  })
  const font_color = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
  dark_color.forEach((v, i) => {
    rgb[i] = cubicEaseOut(start, v, 238 - v, 100)
  })
  const border_color = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
  // "width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
  // const status_bar_height = localStorage.getItem('status_bar_height') || '22'
  return (
    <div>
      <StatusBar color={color} />
      <div
        className={[styles.container, fixed ? styles.fixed : ''].join(' ')}
        style={{
          backgroundColor: color,
          borderBottom: '1px solid '+ border_color,
        }}
      >
        <div className={styles.title} style={{color: font_color}}>
          {title}
        </div>
        <div className={styles.back_btn} onClick={() => history.goBack()}>
          <svg className="icon"
               style={{
                 width: '15px', height: '15px', verticalAlign: 'middle', fill: 'currentcolor', overflow: 'hidden'
               }}
               viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1423">
            <path
              d="M611.17 100.997c16.61-15.357 42.524-14.342 57.881 2.268 15.204 16.444 14.36 42.008-1.775 57.418l-0.493 0.464-382.434 353.584 381.698 350.768c16.49 15.154 17.727 40.701 2.898 57.373l-0.454 0.502c-15.154 16.49-40.7 17.727-57.372 2.898l-0.503-0.454L196.2 544.983c-17.447-16.033-17.662-43.432-0.613-59.744l0.522-0.49L611.17 100.996z"
              fill={font_color} p-id="1424" />
          </svg>
          {/*<img src={back_light_img} alt=""/>*/}
        </div>
      </div>
    </div>
  )
}

export default NavBar
