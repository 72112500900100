import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { getLanguage } from '../../../locate'

function LeaseAgreement() {
  const l = getLanguage().All
  useEffect(() => {
    document.title = l.user_agreement1
  }, [])
  return (
    <div className={styles.main}>
      {/*<p><strong>Glutton</strong>软件<strong>APP</strong>（以下简称“<strong>Glutton</strong>”）推出 租赁宝 服务，为<strong>Glutton</strong>用户（以下简称“您”）提供闲置数字资产增值服务。除<strong>Glutton</strong>另有规定外，经完成<strong>Glutton</strong>账户注册及认证手续后，您同样需要另行与<strong>Glutton</strong>缔结《 租赁宝 服务使用协议》并开设专项账户及/或完成其他相关手续，方可使用 租赁宝 服务(下称：本产品)。在使用该产品服务前，您知晓并同意：</p>*/}

      {/*<p>​		1、<strong>Glutton</strong>为特定币种挖矿提供流动性时，用户添加的资产可以享受对应币种的活期利息，即用户按照产品服务页面规则选定购买的产品类型，在专项账户内存入一定数量对应币种的数字资产，存储期限届满后，用户即可取出原存储数量数字资产，以及存储期间产生的利息。</p>*/}

      {/*<p>​		2、您在申购本产品前，必须确认币对的单价与所需支付的手续费，一旦发出交易指令后，用户的数字货币资产将与<strong>Glutton</strong>的 租赁宝 产品资产执行交换（&quot;交易&quot;）。交易执行后，用户的账户将被更新。为了达成交易，用户授权<strong>Glutton</strong>暂时控制在交易中处置的数字货币;</p>*/}

      {/*<p>​		3、用户同意向<strong>Glutton</strong>支付因交易而产生的费用（手续费），<strong>Glutton</strong>可自行决定随时更新该费用。任何更新费用将适用于更新费用生效日期之后发生的任何买卖或其他交易。用户授权<strong>Glutton</strong>从用户的账户中扣除根据本使用条款所欠的任何适用费用。</p>*/}

      {/*<p>​		4、用户在使用本产品服务时，即无条件授权<strong>Glutton</strong>将收取的手续费、利息等按照平台的规则进行合理的分配和发放。</p>*/}

      {/*<p>​		5、<strong>Glutton</strong>为了更好的为用户提供闲置数字资产增值服务，用户了解并授权<strong>Glutton</strong>暂时控制在购买本服务时所处置的数字货币资产。</p>*/}

      {/*<p>​		6、当币对在市场上发生剧烈波动时，本产品的份额持有者可能无法享有同样的价值增益，因此产品申购行为不是一种无风险的操作，享受的并非保本收益。</p>*/}

      {/*<p>​		7、用户使用本产品服务时，应当遵守国家相关法律，保证资产的来源合法合规；应当充分认识到数字资产投资的风险，谨慎操作，量力而行。</p>*/}

      {/*<p>​		8、用户同意在<strong>Glutton</strong>所进行的所有投资操作代表其真实投资意愿，并无条件接受投资决策所带来的潜在风险和收益。</p>*/}

      {/*<p>​		9、<strong>Glutton</strong>保留暂停、终止 租赁宝 服务的权限，在必要的时候，<strong>Glutton</strong>平台可以随时暂停，终止 租赁宝 服务。</p>*/}

      {/*<p>​		10、由于网络延迟，计算机系统故障及其他可能的不可抗拒因素，可能导致 租赁宝 服务的执行迟缓，暂停，中止或偏差，<strong>Glutton</strong>将尽力保证但不承诺 租赁宝 服务执行系统运行的稳定和有效，但由于上述因素导致的 租赁宝 服务的最终执行结果与用户预期不同，<strong>Glutton</strong>不承担任何责任。</p>*/}

      {/*<p>我已阅读并同意 租赁宝 服务购买协议，并同意购买 租赁宝 业务。我已知晓此风险并确认购买。</p>*/}
      <p>{l.user_agreement2}</p>

      <p>​		{l.user_agreement3}</p>

      <p>​		{l.user_agreement4}</p>

      <p>​		{l.user_agreement5}</p>

      <p>{l.user_agreement6}</p>

      <p>​		{l.user_agreement7}</p>

      <p>​		{l.user_agreement8}</p>

      <p>​		{l.user_agreement9}</p>

      <p>​		{l.user_agreement10}</p>

      <p>​		{l.user_agreement11}</p>

      <p>​		{l.user_agreement12}</p>
      <p>​		{l.user_agreement13}</p>
      <p>​		{l.user_agreement14}</p>
      <p>​		{l.user_agreement15}</p>
      <p>​		{l.user_agreement16}</p>
      <p>​		{l.user_agreement17}</p>
      <p>​		{l.user_agreement18}</p>
      <p>​		{l.user_agreement19}</p>
      <p>​		{l.user_agreement20}</p>
      <p>​		{l.user_agreement21}</p>
      <p>​		{l.user_agreement22}</p>
      <p>​		{l.user_agreement23}</p>
      <p>​		{l.user_agreement24}</p>
      <p>​		{l.user_agreement25}</p>
      <p>​		{l.user_agreement26}</p>
      <p>​		{l.user_agreement27}</p>
      <p>​		{l.user_agreement28}</p>
      <p>​		{l.user_agreement29}</p>
      <p>​		{l.user_agreement30}</p>
    </div>
  )
}
const url=process.env.REACT_APP_API_URL_REGISTER_H5+'protocol'
function  IframeComponent  () {

  // @ts-ignore
  return (
    <iframe src={url} className={styles.iframe}/>
  );
}




export default IframeComponent
