import { Button } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import Popup from '../../../components/popup/Popup'
import styles from './normal-cover.module.css'
import { getLanguage } from '../../../locate'

interface NormalCoverProps {
  show: boolean
  onClose?: () => void
  onConfirm: (value: string) => void
}

function NormalCover(props: NormalCoverProps) {
  const l = getLanguage().All
  const { show, onClose, onConfirm } = props
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue('')
  }, [show])
  return (
    <Popup show={show} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title}>
          <span>{l.transaction41}</span>
        </div>
        <div className={styles.row_inp}>
          <div className={styles.label}>{l.transaction42}</div>
          <div className={styles.inp}>
            <input type="text" disabled value={'4'} />
          </div>
        </div>
        <div className={styles.row_inp}>
          <div className={styles.label}>{l.transaction43}</div>
          <div className={styles.inp}>
            <input type="text" placeholder={l.transaction44} value={value} onChange={e => setValue(e.target.value)} />
          </div>
        </div>
        <div className={styles.tip}>
          <div className={styles.tip_text}>
            <span>*{' '}</span>
            {l.transaction45}160USDT
          </div>
        </div>
        <div className={styles.operation}>
          <button onClick={onClose} className={styles.cancel_btn}>
            {l.transaction21}
          </button>
          <button onClick={() => onConfirm(value)} className={styles.confirm_btn}>
            {l.transaction22}
          </button>
        </div>
      </div>
    </Popup>
  )
}

export default NormalCover
