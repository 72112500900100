import React from 'react'
import { toKeepDecimal } from '../../../utils/tools'
import styles from './param-detail.module.css'
import { getLanguage } from '../../../locate'

interface ParamDetailProps {
  data: any
  amount: number
  is_show: boolean
}

function ParamDetail(props: ParamDetailProps) {
  const l = getLanguage().All
  const { data, amount, is_show } = props
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <span className={styles.label}>
            {l.transaction51}
          </span>
          <span className={styles.value}>
            {is_show ? (
              <>{data.bt_pi}{l.transaction52}  <span style={{whiteSpace: 'nowrap'}}>≈  {toKeepDecimal(amount, 2)}USDT</span></>
            ) : '****'}
          </span>
        </div>
        <div className={styles.col}>
          <span className={styles.label}>
            {l.transaction53}
          </span>
          <span className={styles.value}>
            {is_show ? `${l.transaction54}${data.num}${l.transaction55}` : '****'}
          </span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <span className={styles.label}>
            {l.transaction56}
          </span>
          <span className={styles.value}>
            {is_show ? `${data.down_pi}%` : '****'}
          </span>
        </div>
        <div className={styles.col}>
          <span className={styles.label}>
            {l.transaction57}
          </span>
          <span className={styles.value}>
            {is_show ? `${data.down_return_pi}%` : '****'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ParamDetail
