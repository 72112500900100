import { Tabs } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { getBalance, updateStrategyType } from '../../../api/strategy'
import StatusBar from '../../../components/status-bar/StatusBar'
import { LoopType } from '../../../lib/enums'
import { useQuery } from '../../../utils/custom_hooks'
import { ToastFail } from '../../../utils/http/err_handle'
import search_img from './images/search.png'
import SettingPopup from './SettingPopup'
import TabItem from './TabItem'
import styles from './transaction-list.module.css'
import { getLanguage } from '../../../locate'
const l = getLanguage().All

const tabs = [
  { title: l.transaction129, sub: '1' },
  { title: l.transaction123, sub: '2' },
  { title: l.transaction124, sub: '3' },
];

function TransactionList() {
  const l = getLanguage().All
  const width = (window.innerWidth / 3 - 16) / 2;
  const [settingModal, setSettingModal] = useState(false)
  const [all, setAll] = useState([false, false, false])
  const [active_id, setActiveId] = useState<number[]>([])
  const [currentTab, setCurrentTab] = useState(0)
  const [ix, setIx] = useState(0)
  const { statusBarHeight } = useQuery()
  const [searchText, setSearchText] = useState('')
  const [balance, setBalance] = useState('0.00')
  useEffect(() => {
    if (statusBarHeight)
      localStorage.setItem('status_bar_height', statusBarHeight)
  }, [statusBarHeight])
  const onRefresh = () => {
    getBalance().then(({ data }) => {
      setBalance(data.balance)
    }).catch(ToastFail)
  }
  return (
    <div className={styles.container}>
      <StatusBar theme="dark" />
      <div className={styles.head_bg}>
        <div className={styles.search}>
          <div className={styles.search_img}>
            <img src={search_img} alt=""/>
          </div>
          <div className={styles.search_inp}>
            <input
              type="text" placeholder={l.transaction125}
              value={searchText}
              onInput={event => setSearchText(event.currentTarget.value)}
            />
          </div>
          <div onClick={() => console.log(active_id)} className={styles.search_btn}>
            {l.transaction126}
          </div>
        </div>
        <div className={styles.assets_title}>
          {l.transaction127}（USDT）
        </div>
        <div className={styles.assets_row}>
          <span className={styles.balance}>
            {balance}
          </span>
          <span className={styles.batch_setting}>
            {/*{settingModal && (
              <span
                onClick={() => {
                  all[currentTab] = true
                  setAll(all.slice(0))
                }}
              >
                全选
              </span>
            )}
            {'\u3000\u3000'}*/}
            <span onClick={() => setSettingModal(true)}>
              {l.transaction128}
            </span>
          </span>
        </div>
      </div>
      <div
        key={ix}
        className={styles.tab}
        style={{height: 'calc(100% - ' + (210 + Number(statusBarHeight || 0)) + 'px + 56px)'}}
      >
        <Tabs
          onChange={((tab, index) => {
            setCurrentTab(index)
          })}
          tabs={tabs}
          initialPage={0}
          renderTab={tab => <span>{tab.title}</span>}
          useOnPan={false}
          swipeable={false}
          tabBarActiveTextColor="#2C355C"
          tabBarInactiveTextColor="#7980A1"
          tabBarUnderlineStyle={{
            width: '16px',
            marginLeft: width + 'px',
            height: '2px',
            background: '#2C355C',
            border: 'none'
          }}
        >
          <TabItem
            onRefresh={onRefresh}
            search={searchText} onActive={ids => {
              setActiveId(ids)
              // setAll([false, false, false])
            }}
            all={all[0]}
          />
          <TabItem
            search={searchText} onActive={ids => {
              setActiveId(ids)
              // setAll([false, false, false])
            }}
            all={all[1]}
            type={LoopType.Loop}
          />
          <TabItem
            search={searchText}
            onActive={ids => {
              setActiveId(ids)
              // setAll([false, false, false])
            }}
            all={all[2]}
            type={LoopType.Single}
          />
        </Tabs>
      </div>
      <SettingPopup
        onAll={(active) => {
          all[currentTab] = active
          setAll(all.slice(0))
        }}
        id={active_id}
        onChange={(type) => {
          updateStrategyType(active_id, type)
            .then(() => {
              setIx(ix + 1)
            })
            .catch(ToastFail)
        }}
        onClose={() => setSettingModal(false)}
        show={settingModal}
      />
    </div>
  )
}

export default TransactionList
