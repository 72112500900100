import { Tabs } from 'antd-mobile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { assetsDetails } from '../../../api/lease'
import PullLoadList from '../../../components/pull-load-list/PullLoadList'
import { ToastFail } from '../../../utils/http/err_handle'
import { panNum } from '../../../utils/tools'
import styles from './index.module.scss'
import { getLanguage } from '../../../locate'

interface ItemProps {
  name: string
  date: string
  amount: number
}

function Item(props: ItemProps) {
  const { name, date, amount } = props
  return (
    <div className={styles.item}>
      <div className={styles.itemName}>
        {name}
      </div>
      <div className={styles.itemDate}>
        {date}
      </div>
      <div className={styles.itemAmount} style={amount < 0 ? { color: '#F7A04C' } : undefined}>
        {panNum(amount)}
      </div>
    </div>
  )
}

function AssetsDetail() {
  const l = getLanguage().All

  const tabs = [
    { title: l.assetsDetails1, type: null },
    { title: l.assetsDetails2, type: 20 },
    { title: l.assetsDetails3, type: 21 },
    { title: l.assetsDetails4, type: 22 },
    { title: l.assetsDetails5, type: 23 },
    { title: l.assetsDetails6, type: 24 },
    { title: l.assetsDetails7, type: 25 },
    { title: l.assetsDetails8, type: 33 },
  ]
  const [ lists, setLists ] = useState<any[][]>([])
  useEffect(() => {
    document.title = l.assetsDetails9
  }, [])
  return (
    <div className={styles.main}>
      <Tabs
        useOnPan={false}
        tabs={tabs}
        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={5}/>}
      >
        {(tab: { type: number }, index: number) => (
          <PullLoadList
            click={false}
            onReq={
              pageNum => assetsDetails(tab.type, pageNum)
                .then(({ data }) => {
                  setLists(list => {
                    const cloneList = list.slice(0)
                    cloneList[index] = pageNum.pageNo === 1 ? data.list : cloneList[index].concat(data.list)
                    return cloneList
                  })
                  return data.list
                })
                .catch(ToastFail).then(() => [])
            }
          >
            {(lists[index] || []).map((v, i) => (
              <Item key={i} name={v.typeName} date={moment(v.createTime).format('YYYY-MM-DD HH:mm:ss')}
                    amount={v.amount}/>
            ))}
          </PullLoadList>
        )}
      </Tabs>
    </div>
  )
}

export default AssetsDetail
