import EventEmitter from 'eventemitter3';
import config from '../config';

export enum StatusBarTheme {
  Light = 'light',
  Dark = 'dark'
}

function main(global: any) {
  const event_emitter = new EventEmitter();

  const platform = global.JsAndroid ? 'android' : global.webkit && global.webkit.messageHandlers ? 'ios' : 'web';

  function iosCallback(json_string: any) {
    const param = JSON.parse(json_string);
    event_emitter.emit(param._event, param.data);
  }

  function androidCallback(json_string: any) {
    const param = JSON.parse(json_string);
    event_emitter.emit(param._event, param.data);
  }

  global.iosCallback = iosCallback;
  global.androidCallback = androidCallback;

  const NATIVE_CALLBACK_TIME = 10000;

  function postIosCallback(func: string, message?: object) {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error('timeout'));
      }, NATIVE_CALLBACK_TIME);
      const event = `${Date.now() / Math.random()}`;
      event_emitter.on(event, (param) => {
        clearTimeout(timer);
        event_emitter.removeAllListeners(event);
        resolve(param);
      });
      const fn = global.webkit.messageHandlers[ func ];
      if (!fn) {
        clearTimeout(timer);
        reject(new Error('ios 方法没有找到'));
      } else {
        fn.postMessage(JSON.stringify({
          callBack: "iosCallback",
          _event: event,
          ...message,
        }));
      }

    });
  }

  // function postAndroidCallback(func: string, message: object) {
  //   return new Promise(((resolve, reject) => {
  //     const timer = setTimeout(() => {
  //       reject(new Error('timeout'))
  //     }, NATIVE_CALLBACK_TIME)
  //     const event = (Date.now() / Math.random()) + '';
  //     event_emitter.on(event, (param) => {
  //       clearTimeout(timer)
  //       event_emitter.removeAllListeners(event)
  //       resolve(param)
  //     })
  //     global.goHomeListener[func]?.(JSON.stringify({
  //       callBack: 'androidCallback',
  //       _event: event,
  //       ...message,
  //     }))
  //   }))
  // }

  return {
    platform,
    async goLogin() {
      if (platform === 'web') return
      return platform === 'android' ? global.JsAndroid.goLogin() : postIosCallback('gotoLogin');
    },
    async jump(id: string) {
      return platform === 'android' ? global.JsAndroid.jumpSnapshootl(id) : postIosCallback('jumpSnapshootl', { id })
    },
    async order(strategyId: string) {
      return platform === 'android' ? global.JsAndroid.orderTransfer(strategyId) : postIosCallback('orderTransfer', { strategyId})
    },
    back() {
      if (platform === 'android') {
        global.JsAndroid.back()
      } else {
        postIosCallback('back')
      }
    },
    changeStatusBarColor(theme: StatusBarTheme) {
      switch (platform) {
        case 'android':
          global.JsAndroid.switchTitle(theme === StatusBarTheme.Light ? 'white' : 'black');
          break
        case 'ios':
          global.webkit.messageHandlers.changeStatusBarColor.postMessage(theme);
          break
        default:
      }
    }
  };
}

export default main(window);
