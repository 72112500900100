import { ActivityIndicator } from 'antd-mobile'
import React from 'react'
import ReactDOM from 'react-dom'

const loadings = new Set<HTMLDivElement>()

export function showLoading() {
  const elem = document.createElement('div')
  document.body.appendChild(elem)
  loadings.add(elem)
  ReactDOM.render(<ActivityIndicator
    toast
    text="Loading..."
    animating={true}
  />, elem)
  return () => {
    loadings.delete(elem)
    elem.remove()
  }
}

export function hideAllLoading() {
  loadings.forEach(elem => elem.remove())
  loadings.clear()
}
