import { useLocalObservable, Observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { queryStrategyOrder } from '../../../api/strategy'
import PullLoadList from '../../../components/pull-load-list/PullLoadList'
import { LoopType } from '../../../lib/enums'
import { ToastFail } from '../../../utils/http/err_handle'
import CurrencyItem from './CurrencyItem'
import { getLanguage } from '../../../locate'

interface TabItemProps {
  type?: LoopType
  all?: boolean
  onActive: (ids: number[]) => void
  search: string
  onRefresh?: () => void
}

function TabItem(props: TabItemProps) {
  const l = getLanguage().All
  const { search, type, all, onActive, onRefresh } = props
  const [data, setData] = useState<any[]>([]);
  const activeId = useLocalObservable(() => ({
    id_arr: new Set<number>(),
    add(id: number) {
      this.id_arr.add(id)
    },
    remove(id: number) {
      this.id_arr.delete(id)
    }
  }))
  useEffect(() => {
    if (all) {
      data.forEach(v => {
        activeId.add(v.id)
      })
      onActive(Array.from(activeId.id_arr))
    } else {
      data.forEach(v => {
        activeId.remove(v.id)
      })
    }
  }, [data, all, activeId])
  return (
    <div style={{ height: '100%' }}>
      <PullLoadList
        pageSize={30}
        pullUpLoad={false}
        style={{ width: '100%' }}
        onReq={pageNum => queryStrategyOrder(pageNum, type).then((res) => {
          const list = res.data.lists
          if (pageNum.pageNo === 1) onRefresh?.()
          setData(data => pageNum.pageNo === 1 ? list : data.concat(list))
          return list
        }).catch(ToastFail)}
      >
        <Observer>
          {() => (
            <>
              {
                data.map((v, i) => (
                  v.symbol.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ? (
                    <CurrencyItem active={activeId.id_arr.has(v.id)} onActive={() => {
                      if (activeId.id_arr.has(v.id)) {
                        activeId.remove(v.id)
                      } else {
                        activeId.add(v.id)
                      }
                      onActive(Array.from(activeId.id_arr))
                    }} data={v} key={i} />
                  ) : null
                ))
              }
            </>
          )}
        </Observer>
      </PullLoadList>
    </div>
  )
}

export default TabItem
