import { Toast } from 'antd-mobile'
import { getLanguage } from '../../locate'

export function ToastFail(err: any) {
  const l = getLanguage().Error

  if(err.not_toast) return
  if (err.message === 'Network Error') {
    Toast.info(l.network_error);
  } else if (err.message.includes('timeout')) {
    Toast.info(l.timout_error);
  } else {
    Toast.info(err.message);
  }
}
