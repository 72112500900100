import React, { useState } from 'react'
import styles from './list-item.module.scss'
import { SwipeableListItem } from '../../../../components/swipeable-list-item';
import { getLanguage } from '../../../../locate'

interface ItemProps{
  value:{
    address:string,
    remark:any,
    id:number
  }
  del:()=>void,
  edit:()=>void,
  toBack:()=>void
}
function ListItem(props:ItemProps) {
  const l = getLanguage().All
  return (
    <div className={styles.main}>
      <SwipeableListItem
        swipeLeft={{
          content: <img className={styles.img} src={require('../../imgs/delete.png').default} onTouchEnd={() => props.del()} />,
          action: () => console.info('swipe action triggered'),
          width: 60,
        }}
        threshold={2}
      >
      <div className={styles.item} onClick={()=>props.toBack()}>
        <div className={styles.left}>
          <div className={styles.remark}>{props.value.remark}
          <span>FIL</span>
          </div>
          <div className={styles.value}>{props.value.address}</div>
        </div>
        <div className={styles.right} onClick={(e)=>{
          e.stopPropagation()
          props.edit()
        }}>{l.address1}</div>
      </div>
      </SwipeableListItem>
      <div className={styles.divBg}></div>
    </div>
  )
}

export default ListItem
