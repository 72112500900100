import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import NavBar from '../../components/nav-bar/NavBar'
import { Difficulty } from '../../lib/enums'
import style from './set-deal.module.css'
import {  Tabs } from 'antd-mobile'
import { getLanguage } from '../../locate'

import Tab1 from './tab1'
import Tab2 from './tab2'
import Tab3 from './tab3'
let l = getLanguage().All

const tabs = [
  { title: l.setDeal1, sub: '1' },
  { title: l.setDeal2, sub: '2' },
  { title: l.setDeal3, sub: '3' },
]

function SetDeal() {
  let l = getLanguage().All

  const width = (window.innerWidth / 3 - 88) / 2;
  const location = useLocation()
  let estate = location.state as any
  if (estate?.is_have && estate?.strategyType === Difficulty.Easy) {
    estate = {
      is_have: true,
      name: estate.name,
      num: estate.bt_up,
      firstAmount: estate.first_amount,
      stopPi: estate.stop_pi,
      stopReturnPi: estate.stop_return_pi,
      addDownPi: estate.params[0].down_pi,
      addDownReturnPi: estate.params[0].down_return_pi,
      type: estate.type,
      strategyType: Difficulty.Easy,
    }
  }
  const [state, setState] = useState(estate)
  const [page, setPage] = useState((!estate?.is_have && !estate?.lib) ? 2 : state?.strategyType === Difficulty.Normal ? 1 : 0)
  return (
    <div className={style.container}>
      <NavBar title={l.setDeal4} theme="light"/>
      <div style={{ height: '13px' }} />
      <Tabs
        tabs={tabs}
        tabBarUnderlineStyle={{ borderColor: '#2C355C', width: '88px', marginLeft: width + 'px' }}
        tabBarActiveTextColor="#2C355C"
        tabBarInactiveTextColor="#7980A1"
        useOnPan={false}
        onChange={(tab, index) => {
          setPage(index)
        }}
        swipeable={false}
        page={page}
        onTabClick={(tab, index) => {
          console.log('onTabClick', index, tab)
        }}
      >
        <Tab1 key={state?.name} state={state?.strategyType === Difficulty.Easy ? state : undefined} />
        <Tab2 state={state?.strategyType === Difficulty.Easy ? undefined : state} />
        <Tab3 onSelected={data => {
          setPage(0)
          setState({
            name: data.name,
            num: data.btUp,
            firstAmount: data.firstAmount,
            stopPi: data.stopPi,
            stopReturnPi: data.profitPi,
            addDownPi: data.replenishmentDownPi,
            addDownReturnPi: data.replenishmentReturnPi,
            type: data.type,
            strategyType: Difficulty.Easy,
          })
        }}/>
      </Tabs>
    </div>
  )
}

export default SetDeal
