import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getProductPage, getUserAssets } from '../../../api/lease'
import Null from '../../../components/null/Null'
import PullLoadList from '../../../components/pull-load-list/PullLoadList'
import { ToastFail } from '../../../utils/http/err_handle'
import native_sdk, { StatusBarTheme } from '../../../utils/native_sdk'
import { toKeepDecimal } from '../../../utils/tools'
import ProgressBar from "../components/progressBar"
import styles from './index.module.scss'
import { getLanguage } from '../../../locate'

interface Asset {
  name: string,
  key: string,
  color: string
}

interface Btn {
  name: string,
  color: string,
  method: () => void
}

function LeasingTreasure() {
  const l = getLanguage().All
  useEffect(() => {
    document.title = l.home1
  }, [])
  const [ data, setData ] = useState<any>({})
  const [ list, setList ] = useState<any>([])
  const history = useHistory()
  const getAssets = () => {
    getUserAssets().then(res => {
      setData(res.data)
    }).catch(ToastFail)
  }
  useEffect(() => {
    native_sdk.changeStatusBarColor(StatusBarTheme.Dark)
    getAssets()
    const pathname = history.location.pathname
    const unregister = history.listen((location, _action) => {
      if (location.pathname !== pathname) {
        native_sdk.changeStatusBarColor(StatusBarTheme.Light)
      }
    })
    return () => {
      unregister()
    }
  }, [ history ])
  const assets: Array<Asset> = [ {
    name: l.home2, key: "filBalance", color: "#0066ED"
  }, {
    name: l.home3, key: "spareMoney", color: "#E04867"
  }, {
    name: l.home4, key: "filCount", color: "#0066ED"
  } ]
  const btns: Array<Btn> = [ {
    name: l.home5, color: "#00B559", method: () => {
      history.push('/lease/recharge')
    }
  }, {
    name: l.home6, color: "#E04867", method: () => {
      history.push('/lease/fil')
    }
  }, {
    name: l.home7, color: "#0066ED", method: () => {
      history.push('/lease/myProduct')
    }
  } ]
  //返回rmb
  const getRMB = (): string | undefined => {
    let fil: number = (data.filBalance + data.spareMoney) * data.filCnyPrice
    return toKeepDecimal(fil, 4)
  }
  const getState = (state: number): string => {
    return [ l.home8, l.home9, l.home10, l.home11, l.home12, l.home13 ][state] ?? ''
  }
  return (
    <div className={styles.leasingTreasure}>
      <div className={styles.assets}>
        <div className={styles.assetsText}>
          {l.home14}(FIL) <span className={styles.assetsDetail} onClick={() => {
          history.push('/lease/assets-detail')
        }}> {l.home15}</span>
        </div>
        <div className={styles.assetsMoney}>
          {toKeepDecimal((data.filBalance + data.spareMoney) || 0, 4)}
          <span className={styles.rmb}> ≈ {getRMB()} CNY</span>
        </div>
        <div className={styles.assetsMain}>
          {
            assets.map((asset: Asset, i: number) => (
              <div className={styles.assetsItem} key={i}>
                <span className={styles.assetsName}>{asset.name}</span>
                <span className={styles.assetsNum}
                      style={{ color: asset.color }}> {toKeepDecimal(data[asset.key] || 0, 4)}</span>
              </div>
            ))
          }
        </div>
        <div className={styles.btns}>
          {btns.map((btn, i) => (
            <div className={styles.btnItem} style={{ background: btn.color }} key={i} onClick={btn.method}>
              {btn.name}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.products}>
        <div className={styles.productTitle}>
          {l.home16}
        </div>
        <div className={styles.productsMain}>
          <PullLoadList onReq={(pageNum =>
              getProductPage(pageNum)
                .then(res => {
                  getAssets()
                  const lists = res.data.list
                  setList(pageNum.pageNo === 1 ? lists : list.concat(lists))
                  return lists
                })
                .catch(ToastFail)
          )} empty={!list.length && (<Null/>)}
          > {list.map((e: any, i: number) => (
            <div className={styles.productItem} key={i}
                 onClick={() => {

                   history.push(`/lease/productInfo?id=${e.id}&name=${encodeURI(e.productName)}&expectRate=${e.expectRate}&period=${e.period}&balanceDay=${e.balanceDay}&buyThreshold=${e.buyThreshold}&amount=${e.amount}&spareAmount=${e.spareAmount}&profitStartTime=${e.profitStartTime}&state=${e.state}&applyStartTime=${e.applyStartTime}&applyEndTime=${e.applyEndTime}`)
                 }
                 }>
              <div className={styles.productsName}>
                <img src={require('../imgs/filecoin-logo@2x.png').default} alt=""/>
                {e.productName}
                <span
                  className={[ styles.productsStaus, e.state === 2 ? styles.productsRed : '' ].join(" ")}>{getState(e.state)}</span>
              </div>
              <ProgressBar amount={e.amount} spareAmount={e.spareAmount}/>
              <div className={styles.productsPrice}>
                <span>≈ {e.expectRate}%</span>
                <span className={styles.productsCycle}> <b>{e.period}</b>天</span>
              </div>
              <div className={styles.productsTips}>
                <span>{l.home17}</span>
                <span>{l.home18}</span>
              </div>
              <div className={styles.productsTips}>
                <span>{l.home19}：</span>
                <span className={styles.productsTime}>{moment(e.applyStartTime).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>
              <div className={styles.productsTips}>
                <span>{l.home20}</span>
                <span className={styles.productsTime}>{moment(e.applyEndTime).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>
              <div className={styles.productsTips}>
                <span>{l.home21}</span>
                <span className={styles.productsTime}>{moment(e.profitStartTime).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>
            </div>
          ))}
          </PullLoadList>

        </div>
      </div>
    </div>
  )
}

export default LeasingTreasure
