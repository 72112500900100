import { Toast } from 'antd-mobile'
import { Decimal } from 'decimal.js'
import React, { useEffect, useState } from 'react'
import { apiManualCover } from '../../../api/strategy'
import Popup from '../../../components/popup/Popup'
import encrypt from '../../../utils/encrypt'
import { ToastFail } from '../../../utils/http/err_handle'
import { throttle, toKeepDecimal } from '../../../utils/tools'
import { AmountVerification } from '../../../utils/verification/form-verification'
import styles from './normal-cover.module.css'
import { getLanguage } from '../../../locate'

interface ManualCoverProps {
  id: string,
  show: boolean
  onClose?: () => void
  hold_unit: number // 持仓均价
  current_price: number // 当前价格
  hold_amount: number // 持仓金额
  hold_num: number // 持仓数量
  profit_per: number // 止盈比例
}

function ManualCover(props: ManualCoverProps) {
  const l = getLanguage().All
  const {show, onClose, id, hold_unit = 0, hold_amount = 0, current_price = 0, hold_num, profit_per} = props;
  const [amount, setAmount] = useState('')
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue('')
    setAmount('')
  }, [show])
  const handleSubmit = () => {
    const num = Number(amount)
    if (isNaN(num) || num < 5) {
      return Toast.fail(l.transaction31)
    }
    // if (value === '') {
    //   return Toast.fail('请输入交易密码')
    // }
    apiManualCover(id, amount, encrypt.encrypt(value))
      .then(() => {
        Toast.info(l.transaction32)
        onClose?.();
      })
      .catch(ToastFail)
  }
  const dec_amount = new Decimal(amount || '0')
  // console.log(dec_amount.toString(), hold_amount, hold_num, current_price)
  const after_unit = dec_amount
    .add(hold_amount)
    .div(new Decimal(hold_num).add(dec_amount.mul('0.998').div(current_price)))
  const after_rise = new Decimal(current_price).div(after_unit).sub('1')
  const after_profit = after_unit.mul(new Decimal(profit_per).div('100').add('1'))
  return (
    <Popup show={show} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title}>
          <span>{l.transaction33}</span>
        </div>
        <div className={styles.row_inp}>
          <div className={styles.label}>{l.transaction34}</div>
          <div className={styles.inp}>
            <input
              type="text"
              placeholder={l.transaction35}
              value={amount}
              onChange={e => setAmount(e.currentTarget.value === '' ? '' : (AmountVerification(e.currentTarget.value) || amount))}
            />
          </div>
        </div>
        <div className={styles.state_info}>
          <div className={styles.state_info_item}>
            <div className={styles.state_label}>{l.transaction36}</div>
            <div className={styles.value}>{hold_unit}</div>
          </div>
          <div className={styles.state_info_item}>
            <div className={styles.state_label}>{l.transaction37}</div>
            <div className={styles.value}>{current_price}</div>
          </div>
          <div className={styles.state_info_item}>
            <div className={styles.state_label}>{l.transaction38}</div>
            <div className={styles.value}>≈ {toKeepDecimal(after_unit.toString(), 8)}</div>
          </div>
          <div className={styles.state_info_item}>
            <div className={styles.state_label}>{l.transaction39}</div>
            <div className={styles.value}>≈ {toKeepDecimal(after_rise.mul('100').toString(), 2)}%</div>
          </div>
          <div className={styles.state_info_item}>
            <div className={styles.state_label}>{l.transaction40}</div>
            <div className={styles.value}>≈ {toKeepDecimal(after_profit.toString(), 8)}</div>
          </div>
        </div>
        {/*<div className={styles.row_inp}>
          <div className={styles.label}>交易密码</div>
          <div className={styles.inp}>
            <input type="password" placeholder="请输入交易密码" value={value} onChange={e => setValue(e.target.value)} />
          </div>
        </div>*/}
        <div style={{height: '20px'}} />
        <div className={styles.operation}>
          <button onClick={onClose} className={styles.cancel_btn}>
            {l.transaction21}
          </button>
          <button onClick={throttle(handleSubmit)} className={styles.confirm_btn}>
            {l.transaction22}
          </button>
        </div>
      </div>
    </Popup>
  )
}

export default ManualCover
