import React, { ImgHTMLAttributes } from 'react'
import styles from './text-field.module.scss'
import { getLanguage } from '../../../../locate'

interface TextFieldProps extends React.HTMLProps<HTMLInputElement> {
  label: string
  unit?: string
  onAll?: () => void
  icon?: string
  toAddress?:()=>void
}

function TextField(props: TextFieldProps) {
  const l = getLanguage().All
  const {
    label,
    unit,
    onAll,
    placeholder = '',
    type = 'text',
    value,
    onChange,
    readOnly,
    icon,
    toAddress,
  } = props
  return (
    <div>
      <div className={styles.label}>{label}</div>
      <div className={styles.control}>
        <input type={type} placeholder={placeholder} value={value} onChange={onChange} readOnly={readOnly} />
        <div className={styles.right}>
          {unit && <span className={styles.unit}>{unit}</span>}
          {unit && typeof onAll !== 'undefined' && <div className={styles.colLine} />}
          {typeof onAll !== 'undefined' && <span onClick={onAll} className={styles.all}>{l.assetsDetails1}</span>}
          {icon && <div className={styles.bar} onClick={toAddress}><img src={icon}  className={styles.icon} /></div>}
        </div>
      </div>
    </div>
  )
}

export default TextField
