import { Toast } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { deleteFilAddressById, getFilAddressByUid } from '../../../api/lease'
import { ToastFail } from '../../../utils/http/err_handle'
import ListItem from './components/ListItem'
import NullData from './components/NullData'
import styles from './index.module.scss'
import { getLanguage } from '../../../locate'

function Index() {
  const l = getLanguage().All
  const [addressList,setAddressList]=useState([])
  const history = useHistory()
  const initData=()=>{
    getFilAddressByUid().then(res=>{
      setAddressList(res.data.list)
    }).catch(ToastFail)
  }
  useEffect(()=>{
    document.title=l.address8
    initData();
    const pathname = history.location.pathname
    const unregister = history.listen((location, _action) => {
      if (location.pathname === pathname) {
        initData();
      }
    })
    return () => {
      unregister()
    }
  },[])
  const del=(id:number)=>{
    deleteFilAddressById(id).then(res=>{
      Toast.success(l.address9)
      initData()
    }).catch(ToastFail)
  }
  const edit=(v:any)=>{
    history.push({pathname:'/lease/editAddressList',state:v})
  }
  const toBack=(address:string)=>{
    sessionStorage.setItem('address',address)
    history.goBack()
  }
  return (
    <div className={styles.main}>
      {!addressList.length && <NullData/>}
      <div className={styles.scroll}>
        {
          addressList.map((v:any,i)=>{
            return  <ListItem key={i} value={v} del={()=>del(v.id)} edit={()=>edit(v)} toBack={()=>toBack(v.address)}/>
          })
        }
      </div>
      <div className={styles.btn}>
        <button onClick={()=>history.push('/lease/insertAddressList')}>{l.address10}</button>
      </div>
    </div>
  )
}

export default Index
