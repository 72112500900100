import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { clone } from 'ramda'
import React, { FormEvent, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { updateFilAddressById } from '../../../api/lease'
import { ToastFail } from '../../../utils/http/err_handle'
import { English } from '../../../utils/verification/form-verification'
import TextField from '../fil-tb/components/TextField'
import styles from './insert.module.scss'
import { getLanguage } from '../../../locate'

function Edit() {
  const l = getLanguage().All
  const location = useLocation<any>();

  const initialFormState = location.state || {
    address: '',
    remark: '',
    id:''
  }
  const data = useLocalObservable(() => ({
    value: clone(initialFormState),
    setValue(key: keyof typeof initialFormState, val: string) {
      this.value[key] = val
    },
    clearValue() {
      this.value = clone(initialFormState)
    }
  }), { setValue: action, clearValue: action })
  const form = data.value
  const  history= useHistory()
  const handleChange = (key: keyof typeof initialFormState, verification: Function = (text: string) => text) => (event: FormEvent<HTMLInputElement> | string) => {
    let value = typeof event === 'string' ? event : event.currentTarget.value
    data.setValue(key, value === '' ? ''
      : (verification(value) || data.value[key]) as any)
  }
  const submit=()=>{
    updateFilAddressById(form).then(
      res=>{
        console.log(res)
        history.goBack()
      }
    ).catch(ToastFail)
  }

  useEffect(() => {
    document.title = l.address2
  }, [])
  return (
    <div className={styles.main}>
      <div className={styles.scroll}>
        <div className={styles.textField}>
          <TextField
            onChange={handleChange('address',English)}
            value={form.address}
            label={l.address3}
            placeholder={l.address4}
          />
        </div>
        <div className={styles.textField}>
          <TextField
            onChange={handleChange('remark')}
            value={form.remark}
            label={l.address5}
            placeholder={l.address6}
          />
        </div>
      </div>
      <div className={styles.btn}>
        <button onClick={submit}>{l.address7}</button>
      </div>
    </div>
  )
}

export default observer(Edit)
