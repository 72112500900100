import Decimal from 'decimal.js'
import React, { useEffect, useState } from 'react'
import { Modal,Toast } from 'antd-mobile'
import { ToastFail } from '../../../../utils/http/err_handle'
import native_sdk from '../../../../utils/native_sdk'
import { toKeepDecimal } from '../../../../utils/tools'
import { AmountVerification } from '../../../../utils/verification/form-verification'
import styles from './dumpsPositions.module.scss'
import { clearStrategy, splitOrder } from '../../../../api/strategy'
import { getLanguage } from '../../../../locate'

function SplitBill(props: any) {
  const l = getLanguage().All
  let { visible, setVisible, holdAmount, holdNum ,symbol,strategyId} = props
  const [ num, setNum ] = useState<any>('')
  const [ dumpNum, setDumpNum ] = useState<any>('0.0000')
  const onConfirm=()=>{
    if (Number(num)>Number(holdAmount)){
      return Toast.info(l.transaction18, 2, () => { })
    }
    Modal.alert(l.transaction19, l.transaction20, [
      {
        text:l.transaction21, onPress: () => {
        }
      },
      {
        text:l.transaction22, onPress: () => {
          splitOrder({id:strategyId,amount:num}).then(res=>{
            Toast.success(l.transaction23, 2, () => {
              setVisible(false)
            })
          }).catch(ToastFail)
        }
      },
    ])
  }
  return (
    <Modal
      popup
      className={styles.dumpsPositions}
      visible={visible}
      onClose={() => setVisible(false)}
      animationType="slide-up">
      <div className={styles.content}>
        <div className={styles.cion}>
          <span>{l.transaction24}</span>
          <span>{l.transaction25}{holdAmount}</span>
        </div>
        <div className={styles.inputBox}>
          <div className={styles.inputTittle}>
            {l.transaction26}(USDT）
          </div>
          <div className={styles.input}>
            <input type="number" placeholder={l.transaction27} value={num} onChange={(e) => {
              let { value } = e.currentTarget
              value = value === '' ? value : AmountVerification(value) || num
              setNum(value)
              if (value){
                let bl: any = new Decimal(value).div(Number(holdAmount))
                let dump: any = bl.mul(holdNum)
                dump = toKeepDecimal(Number(dump) || '0', 4)
                setDumpNum(dump)
              }else {
                setDumpNum(toKeepDecimal('0', 4))
              }
            }}/>
          </div>
        </div>
        <div className={styles.inputBox}>
          <div className={styles.inputTittle}>
            {l.transaction28}({symbol.split('/')[0]})
          </div>
          <div className={styles.input}>
            <input type="text" readOnly value={dumpNum}/>
          </div>
        </div>
        <div className={styles.tips}>
          {l.transaction29}
        </div>
        <div className={styles.btns}>
          <div className={styles.btn} onClick={onConfirm}>{l.transaction30}</div>
        </div>
      </div>
    </Modal>
  )
}

export default SplitBill
