import React from 'react'
import styles from './popup.module.css'

interface PopupProps {
  show: boolean
  children: React.ReactNode
  onClose?: () => void
}

function Popup(props: PopupProps) {
  const {show, onClose} = props
  return (
    <div
      onClick={() => onClose?.()}
      className={[styles.container, show ? styles.show : styles.hide].join(' ')}
    >
      <div onClick={e => e.stopPropagation()} className={[styles.content, show ? styles.show : styles.hide].join(' ')}>
        {props.children}
      </div>
    </div>
  )
}

export default Popup
