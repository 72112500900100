import { Modal, Toast } from 'antd-mobile'
import { isEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'
import BottomPopup from '../../../components/bottom-popup/BottomPopup'
import { LoopType } from '../../../lib/enums'
import close_img from './images/close.png'
import clear_img from './images/qingcang.png'
import single_img from './images/single.png'
import loop_img from './images/xunhuan.png'
import styles from './setting-popup.module.css'
import checkbox_img from './images/choose.png'
import checkbox_border_img from './images/no_choose.png'
import edit_img from './images/xiugai.png'
import start_img from './images/start.png'
import stop_img from './images/stop.png'
import { getLanguage } from '../../../locate'

interface SettingPopupProps {
  show: boolean
  onClose: () => void
  onChange: (type: LoopType) => void
  id: number[]
  onAll: (selected: boolean) => void
}

function SettingPopup(props: SettingPopupProps) {
  const l = getLanguage().All
  const { onChange, id, onAll } = props
  const [loop, setLoop] = useState(true)
  const [all, setAll] = useState(false)
  useEffect(() => {
    onAll(all)
  }, [all])
  return (
    <BottomPopup
      visible={props.show}
    >
      <div className={styles.modal_nav}>
        <div className={styles.checkbox} onClick={() => setAll(!all)}>
          {all ? <img src={checkbox_img} alt=""/> : <img src={checkbox_border_img} alt=""/>}
          <span>{l.transaction114}</span>
        </div>
        <img src={close_img} alt="" onClick={() => props.onClose()}/>
      </div>
      <div className={styles.modal_container}>
        <div
          className={styles.modal_item}
          onClick={() => {
            if (isEmpty(id)) {
              return Toast.fail(l.transaction115)
            }
          }}
        >
          <div className={styles.icon}>
            <img src={edit_img} alt=""/>
          </div>
          <div className={styles.name}>
            {l.transaction116}
          </div>
        </div>
        <div
          className={styles.modal_item}
          onClick={() => {
            if (isEmpty(id)) {
              return Toast.fail(l.transaction115)
            }
          }}
        >
          <div className={styles.icon}>
            <img src={stop_img} alt=""/>
          </div>
          <div className={styles.name}>
            {l.transaction117}
          </div>
        </div>
        <div
          className={styles.modal_item}
          onClick={() => {
            if (isEmpty(id)) {
              return Toast.fail(l.transaction115)
            }
            onChange(loop ? LoopType.Loop : LoopType.Single)
            setLoop(!loop)
          }}
        >
          <div className={styles.icon}>
            <img src={loop ? loop_img : single_img} alt=""/>
          </div>
          <div className={styles.name}>
            {loop ? l.transaction118 : l.transaction119}
          </div>
        </div>
        <div
          className={styles.modal_item}
          onClick={() => {
            if (isEmpty(id)) {
              return Toast.fail(l.transaction115)
            }
            Modal.alert(l.transaction120, l.transaction121, [
              {
                text: l.transaction21
              },
              {
                text: l.transaction22,
                onPress: () => {
                  console.log(id)
                }
              }
            ])
          }}
        >
          <div className={styles.icon}>
            <img src={clear_img} alt=""/>
          </div>
          <div className={styles.name}>
            {l.transaction122}
          </div>
        </div>
      </div>
    </BottomPopup>
  )
}

export default SettingPopup
