import React from 'react'
import styles from './param-detail.module.css'
import { getLanguage } from '../../../locate'

interface ParamDetailProps {
  data: any
  is_show: boolean
}

function ParamDetail(props: ParamDetailProps) {
  const l = getLanguage().All

  const { data, is_show } = props
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <span className={styles.label}>
            {l.transaction46}
          </span>
          <span className={styles.value}>
            {data.first_amount}
          </span>
        </div>
        <div className={styles.col}>
          <span className={styles.label}>
            {l.transaction47}
          </span>
          <span className={styles.value}>
            {is_show ? l.transaction48 : '****'}
          </span>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <span className={styles.label}>
            {l.transaction49}
          </span>
          <span className={styles.value}>
            {is_show ? `${data.stop_pi || 0}%` : '****'}
          </span>
        </div>
        <div className={styles.col}>
          <span className={styles.label}>
            {l.transaction50}
          </span>
          <span className={styles.value}>
            {is_show ? `${data.stop_return_pi || 0}%` : '****'}
          </span>
        </div>
      </div>
      {/*<div className={styles.row}>
        <div className={styles.col}>
          <span className={styles.label}>
            补仓跌幅
          </span>
          <span className={styles.value}>
            {data.down_pi}%
          </span>
        </div>
        <div className={styles.col}>
          <span className={styles.label}>
            补仓回调
          </span>
          <span className={styles.value}>
            {data.down_return_pi}%
          </span>
        </div>
      </div>*/}
    </div>
  )
}

export default ParamDetail
