import React, { useState } from 'react'
import { LoopType } from '../../../lib/enums'
import { LOCAL_STRATEGY_ID } from '../../../lib/global-key'
import { panNum } from '../../../utils/tools'
import styles from './currency-item.module.css'
import checkbox_img from './images/choose.png'
import checkbox_border_img from './images/no_choose.png'
import loop_img from './images/xh.png'
import single_img from './images/dc.png'
import {useHistory} from 'react-router-dom'
import { getLanguage } from '../../../locate'

interface CurrencyItemProps {
  data: {
    zf: number,
    id: number,
    num: number,
    fk: number,
    symbol: string,
    orderType: number,
  },
  active: boolean
  onActive: () => void
}

function CurrencyItem(props: CurrencyItemProps) {
  const l = getLanguage().All
  const { data, active, onActive } = props
  const [itemActive, setItemActive] = useState(false)
  const history = useHistory()
  return (
    <div
      // className={[styles.item, 'currency-item'].join(' ')}
      className={[styles.item, itemActive ? styles.item_active : ''].join(' ')}
      onTouchStart={() => setItemActive(true)}
      onTouchEnd={() => setItemActive(false)}
      onClick={() => {
        localStorage.setItem(LOCAL_STRATEGY_ID, String(data.id));
        history.push('/transaction/detail?symbol=' + data.symbol + '&id=' + data.id)
      }}
    >
      <div className={styles.left}>
        <div
          className={styles.checkbox}
          onClick={(event) => {
            event.stopPropagation()
            onActive()
          }}
        >
          {active ? <img src={checkbox_img} alt=""/> : <img src={checkbox_border_img} alt="" />}
        </div>
        <div>
          <div className={styles.row1}>
            <div className={styles.currency}>
              {data.symbol.split('/')[0]}<span className={styles.usdt}>/{data.symbol.split('/')[1]}</span>
            </div>
            <div className={styles.loop}>
              <img src={data.orderType === LoopType.Single ? single_img : loop_img} alt=""/>
              <span>{data.orderType === LoopType.Single ? l.transaction110 : l.transaction111}</span>
            </div>
          </div>
          <div className={styles.row2}>
            <div className={styles.num}>
              {l.transaction112} {data.num}
            </div>
            <div className={styles.float_loss}>
              {l.transaction113} <span className={styles.down}>{panNum(data.fk)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={[styles.right, data.zf < 0 ? styles.down_bg : styles.up_bg].join(' ')}>
        {data.zf}%
      </div>
    </div>
  )
}

export default CurrencyItem
